import React, { useState, useRef } from "react";
import "../../../Common Components/Hero.css";
import "./EnterpriseCaseStudy.css";
import ICICIBankCaseStudy from "../../../assets/pdf/ICICIBankCaseStudy.pdf";
import CaseStudyIndividualPage from "../../../assets/images/CaseStudyIndividualPage.jpg";

const ICICI = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const emailInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userEmail = emailInputRef.current.value;
    const formId = "1FAIpQLScCh_Wn_mi8GlNCFJg0xw82iFGq9m3a7Z2STUBKRA2sSh9TKQ";
    const emailEntryId = "entry.1870394174";
    const nameEntryId = "entry.1812720550";
    const phoneEntryId = "entry.97349512";
    const messageEntryId = "entry.231532781";
    const customMessage = "ICICI case study downloaded";

    // Construct the form submission URL
    const formUrl = `https://docs.google.com/forms/d/e/${formId}/formResponse?${emailEntryId}=${encodeURIComponent(
      userEmail
    )}&${nameEntryId}=${encodeURIComponent(
      customMessage
    )}&${phoneEntryId}=${encodeURIComponent(
      customMessage
    )}&${messageEntryId}=${encodeURIComponent(customMessage)}`;

    try {
      // Submit the form data using Fetch API
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
      });

      // Show the download button upon successful submission
      setShowPopup(false);
      setShowDownloadPopup(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowDownloadPopup(false);
  };

  const handleDownloadPDF = () => {
    // Create an anchor element
    const link = document.createElement("a");
    // Set its href attribute to the PDF file path
    link.href = ICICIBankCaseStudy;
    // Set the download attribute to force download
    link.setAttribute("download", "ICICI Bank Case Study.pdf");
    // Simulate a click event to trigger the download
    link.click();
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
    setShowDownloadPopup(false);
  };

  return (
    <div className="Individual-CaseStudy-Div">
      <div
        className="ImageBackgroundWrap Background-fixed"
        style={{
          backgroundImage: `url(${CaseStudyIndividualPage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="ImageInnerWrap"></div>
      </div>

      <div className="headline" id="CaseStudyDv">
        <h1>ICICI BANK</h1>
      </div>
      <div className="case-study-div">
        <div className="case-study">
          <h2 className="case-study-title">
            A leading private financial institution.
          </h2>
          <div className="case-study-table">
            <table>
              <tbody>
                <tr>
                  <td className="table-label">Assignment name:</td>
                  <td>Secure Wireless Network design, supply and deploy</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Approx. value of the contract:
                  </td>
                  <td>INR 2,10,00,000</td>
                </tr>
                <tr>
                  <td className="table-label">Country:</td>
                  <td>India</td>
                </tr>
                <tr>
                  <td className="table-label">Location within country:</td>
                  <td>Mumbai (PAN India offices commissioning)</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Duration of assignment (months):
                  </td>
                  <td>6</td>
                </tr>
                <tr>
                  <td className="table-label">Name of client:</td>
                  <td>ICICI Bank (technology group - corporate office)</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Total No of staff-months of the assignment:
                  </td>
                  <td>6</td>
                </tr>
                <tr>
                  <td className="table-label">Address:</td>
                  <td>
                    ICICI Bank Towers, Bandra Kurla Complex, Mumbai - 400 051
                    Maharashtra, India
                  </td>
                </tr>
                <tr>
                  <td className="table-label">
                    Approx. value of the services provided by your firm under
                    the contract:
                  </td>
                  <td>INR 2,10,00,000</td>
                </tr>
                <tr>
                  <td className="table-label">Start date (month/year):</td>
                  <td>January 2020</td>
                </tr>
                <tr>
                  <td className="table-label">Completion date (month/year):</td>
                  <td>On-going</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button className="read-more-btn" onClick={handleOpenPopup}>
            Read More
          </button>
          {showPopup && (
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
              <p className="popup-message">
                Want to read more of this case study? Please give us your email:
              </p>
              <form onSubmit={handleSubmit} className="email-form">
                <input
                  type="email"
                  ref={emailInputRef}
                  name="email"
                  placeholder="Enter your email"
                  required
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
          {showDownloadPopup && (
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
              <p>
                Thank you for your email. Your case study PDF is ready for
                download:
              </p>
              <div className="download-button-container">
                <button className="download-btn" onClick={handleDownloadPDF}>
                  Download PDF
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ICICI;
