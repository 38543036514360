// EnterpriseSwitchingSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseSwitchingSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import DGS151028X from "../../../assets/pdf/DGS1510-28X.pdf";
import DGS313030 from "../../../assets/pdf/DGS3130-30.pdf";
import DGS363030 from "../../../assets/pdf/DGS3630-30.pdf";
import ECS410028T from "../../../assets/pdf/ECS4100-28T.pdf";
import ECS410028P from "../../../assets/pdf/ECS4100-28P.pdf";
import ArubaSMB1830 from "../../../assets/pdf/Aruba-SMB-1830.pdf";
import ArubaSMB1930 from "../../../assets/pdf/Aruba-SMB-1930.pdf";
import ArubaSMB1960 from "../../../assets/pdf/Aruba-SMB-1960.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";


const EnterpriseSwitchingSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Dlink",
  //   2: "Edgecore",
  //   3: "Aruba",
  // };

  const productDataList1 = [
    // DGS1510-28X
    {
      images: [
        require("../../../assets/productImages/DGS1510-28X-1.png"),
        require("../../../assets/productImages/DGS1510-28X-2.png"),
        require("../../../assets/productImages/DGS1510-28X-3.png"),
        require("../../../assets/productImages/DGS1510-28X-1.png"),
      ],
      title: "D-Link DGS1510-28X",
      about:(
        <>
        <p>The D-Link DGS-1510 Series is the latest generation of Smart Managed switches with 10G capability, available with 16, 24, or 48 10/100/1000 Mbps ports plus additional fiber ports for physical stacking or uplinks.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of D-Link DGS1510-28X in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for D-Link DGS1510-28X.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>24 x 10/100/1000BASE-T ports.</li>
          <li>4 x 10G SFP+ ports.</li>
          <li>Layer 2 management and security with Layer 3 capabilities.</li>
        </>
      ),
      downloadLink: DGS151028X,
      hyperlinks: [],
    },

    //DGS3130-30
    {
      images: [
        require("../../../assets/productImages/DGS3130-30-1.png"),
        require("../../../assets/productImages/DGS3130-30-2.png"),
        require("../../../assets/productImages/DGS3130-30-3.png"),
        require("../../../assets/productImages/DGS3130-30-4.png"),
      ],
      title: " D-Link DGS3130-30",
      about:(
        <>
        <p>The DMS-3130 Series is a range of Layer 3 Stackable Managed Switches designed to connect end-users in a secure enterprise or metro Ethernet access network.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of D-Link DGS3130-30 in West Bengal, Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for D-Link DGS3130-30.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>64 Byte Packet Forwarding Rate 298 Mpps</li>
          <li>8 x 100M/1/2.5/5GBASE-T 60W PoE ports</li>
          <li>10/100/1000BASE-T RJ-45 port for out-of-band IP management</li>
        </>
      ),
      downloadLink: DGS313030,
      hyperlinks: [],
    },

    //DGS3630-30
    {
      images: [
        require("../../../assets/productImages/DGS3630-30-1.png"),
        require("../../../assets/productImages/DGS3630-30-2.png"),
        require("../../../assets/productImages/DGS3630-30-3.png"),
        require("../../../assets/productImages/DGS3630-30-4.png"),
      ],
      title: "D-Link DGS3630-30",
      about:(
        <>
        <p>The DGS-3630 Series Layer 3 Stackable Managed Switches are designed for Small to Mediumsized Businesses, Small to Medium-sized Enterprises, large enterprises, and Internet Service Providers.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of D-Link DGS3630-30 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for D-Link DGS3630-30.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>20/44 10/100/1000BASE-T ports or 20 SFP ports
          </li>
          <li>
          4 Combo 10/100/1000BASE-T/SFP ports
          </li>
          <li> 4 10 GbE SFP+ uplink ports</li>
          <li>full L3
          routing for enterprise integration, including OSPF, BGP, VRF-Lite and L3 multicast</li>
        </>
      ),
      downloadLink: DGS363030,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //ECS4100-28T
    {
      images: [
        require("../../../assets/productImages/ECS4100-28T-1.png"),
        require("../../../assets/productImages/ECS4100-28T-2.png"),
        require("../../../assets/productImages/ECS4100-28T-3.png"),
        require("../../../assets/productImages/ECS4100-28T-1.png"),
      ],
      title: " Edgecore ECS4100-28T",
      about:(
        <>
        <p>The Edgecore ECS4100 series is ideal for Internet Service Providers (ISPs) and Multiple System Operators (MSOs) to provide home users with triple-play services and up to a Gigabit of bandwidth.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Edgecore ECS4100-28T in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Edgecore ECS4100-28T.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>24 x 1GbE</li>
          <li>56 Gbps Switching Capacity</li>
          <li>Support ecCLOUD</li>
        </>
      ),
      downloadLink: ECS410028T,
      hyperlinks: [],
    },

    //ECS4100-28P
    {
      images: [
        require("../../../assets/productImages/ECS4100-28P-1.png"),
        require("../../../assets/productImages/ECS4100-28P-2.png"),
        require("../../../assets/productImages/ECS4100-28P-3.png"),
        require("../../../assets/productImages/ECS4100-28P-1.png"),
      ],
      title: "Edgecore ECS4100-28P",
      about:(
        <>
        <p>The switch is ideal for Internet Service Providers (ISPs) and Multiple System Operators (MSOs) to provide home users with triple-play services and up to a Gigabit of bandwidth.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Edgecore ECS4100-28P in Delhi, Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Edgecore ECS4100-28P.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>24 x 1GbE (PoE).</li>
          <li>56 Gbps Switching Capacity.</li>
          <li>Support ecCLOUD.</li>
        </>
      ),
      downloadLink: ECS410028P,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //Aruba SMB 1830
    {
      images: [
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
      ],
      title: "Aruba SMB 1830",
      about:(
        <>
        <p>The HPE Networking Instant On Switch Series 1830 is an affordable, smart-managed switch solution for small businesses looking for cost-effective ways to keep up with evolving networking demands.
</p>
<p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba SMB 1830 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Aruba SMB 1830.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Power over Ethernet (PoE) support.</li>
          <li>Flexible management options.</li>
          <li>Integrated security features.</li>
        </>
      ),
      downloadLink: ArubaSMB1830,
      hyperlinks: [],
    },

    //Aruba SMB 1930
    {
      images: [
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1830-1.png"),
      ],
      title: "Aruba SMB 1930",
      about:(
        <>
        <p>The HPE Networking Instant On Switch Series 1930 empowers you to do more. Affordable and easy-to-deploy, fixed-configuration Gigabit switches are designed for today's bandwidth-heavy applications.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Aruba SMB 1930 in Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Aruba SMB 1930.</p>
       </>
      ),
      enterpriseFeature: (
        <>
          <li>Advanced QoS (Quality of Service) for traffic prioritization</li>
          <li>Advanced control and management</li>
          <li>SFP/SFP+ fiber connectivity that goes the distance</li>
        </>
      ),
      downloadLink: ArubaSMB1930,
      hyperlinks: [],
    },

    //Aruba SMB 1960
    {
      images: [
        require("../../../assets/productImages/Aruba-SMB-1960-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1960-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1960-1.png"),
        require("../../../assets/productImages/Aruba-SMB-1960-1.png"),
      ],
      title: "HPE Networking Instant On Switch Series 1960",
      about:(
        <>
        <p>HPE Aruba Instant On access and aggregate switches are advanced, smart-managed, stackable gigabit and multi-gigabit switches.
        </p>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE Aruba Instant On Switch Series 1960 in  West Bengal, Andra Pradesh, Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for HPE Aruba Instant On Switch Series 1960.</p>
       
        </>
      ),
      enterpriseFeature: (
        <>
          <li>High-performance 10 gig connectivity.</li>
          <li>Stack up to four (4) Instant On 1960 switches.</li>
          <li>Multi-gig Ethernet to accelerate business.</li>
        </>
      ),
      downloadLink: ArubaSMB1960,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="switching-enterprise-page">
      <Helmet>
        <title>Switches Dealer Distibutor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Switches Dealer in Pune" />
        <meta name="keywords" content="Best Switches Dealer in Pune" />
        <meta name="keywords" content="Switches Distributor in Pune" />

        <meta name="keywords" content="Switches Dealer in Mumbai" />
        <meta name="keywords" content="Best Switches Dealer in Mumbai" />
        <meta name="keywords" content="Switches Distributor in Mumbai" />

        <meta name="keywords" content="Switches Dealer in Maharashtra" />
        <meta name="keywords" content="Best Switches Dealer in Maharashtra" />
        <meta name="keywords" content="Switches Distributor in Maharashtra" />

        <meta name="keywords" content="Switches Dealer in Vadodara" />
        <meta name="keywords" content="Best Switches Dealer in Vadodara" />
        <meta name="keywords" content="Switches Distributor in Vadodara" />

        <meta name="keywords" content="Switches Dealer in Surat" />
        <meta name="keywords" content="Best Switches Dealer in Surat" />
        <meta name="keywords" content="Switches Distributor in Surat" />

        <meta name="keywords" content="Switches Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Switches Dealer in Ahmedabad" />
        <meta name="keywords" content="Switches Distributor in Ahmedabad" />

        <meta name="keywords" content="Switches Dealer in Gujarat" />
        <meta name="keywords" content="Best Switches Dealer in Gujarat" />
        <meta name="keywords" content="Switches Distributor in Gujarat" />

        <meta name="keywords" content="Switches Dealer in Indore" />
        <meta name="keywords" content="Best Switches Dealer in Indore" />
        <meta name="keywords" content="Switches Distributor in Indore" />

        <meta name="keywords" content="Switches Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Switches Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Switches Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Switches Dealer in Jaipur" />
        <meta name="keywords" content="Best Switches Dealer in Jaipur" />
        <meta name="keywords" content="Switches Distributor in Jaipur" />

        <meta name="keywords" content="Switches Dealer in Rajasthan" />
        <meta name="keywords" content="Best Switches Dealer in Rajasthan" />
        <meta name="keywords" content="Switches Distributor in Rajasthan" />

        <meta name="keywords" content="Switches Dealer in Noida" />
        <meta name="keywords" content="Best Switches Dealer in Noida" />
        <meta name="keywords" content="Switches Distributor in Noida" />

        <meta name="keywords" content="Switches Dealer in Gurugram" />
        <meta name="keywords" content="Best Switches Dealer in Gurugram" />
        <meta name="keywords" content="Switches Distributor in Gurugram" />

        <meta name="keywords" content="Switches Dealer in Delhi" />
        <meta name="keywords" content="Best Switches Dealer in Delhi" />
        <meta name="keywords" content="Switches Distributor in Delhi" />

        <meta name="keywords" content="Switches Dealer in Kolkata" />
        <meta name="keywords" content="Best Switches Dealer in Kolkata" />
        <meta name="keywords" content="Switches Distributor in Kolkata" />

        <meta name="keywords" content="Switches Dealer in West Bengal" />
        <meta name="keywords" content="Best Switches Dealer in West Bengal" />
        <meta name="keywords" content="Switches Distributor in West Bengal" />

        <meta name="keywords" content="Switches Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Switches Dealer in Bhubaneswar" />
        <meta name="keywords" content="Switches Distributor in Bhubaneswar" />

        <meta name="keywords" content="Switches Dealer in Odisha" />
        <meta name="keywords" content="Best Switches Dealer in Odisha" />
        <meta name="keywords" content="Switches Distributor in Odisha" />

        <meta name="keywords" content="Switches Dealer in Hyderabad" />
        <meta name="keywords" content="Best Switches Dealer in Hyderabad" />
        <meta name="keywords" content="Switches Distributor in Hyderabad" />

        <meta name="keywords" content="Switches Dealer in Telangana" />
        <meta name="keywords" content="Best Switches Dealer in Telangana" />
        <meta name="keywords" content="Switches Distributor in Telangana" />

        <meta name="keywords" content="Switches Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Switches Dealer in Visakhapatnam" />
        <meta name="keywords" content="Switches Distributor in Visakhapatnam" />

        <meta name="keywords" content="Switches Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best Switches Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Switches Distributor in Andhra Pradesh" />

        <meta name="keywords" content="Switches Dealer in Chennai" />
        <meta name="keywords" content="Best Switches Dealer in Chennai" />
        <meta name="keywords" content="Switches Distributor in Chennai" />

        <meta name="keywords" content="Switches Dealer in Coimbatore" />
        <meta name="keywords" content="Best Switches Dealer in Coimbatore" />
        <meta name="keywords" content="Switches Distributor in Coimbatore" />

        <meta name="keywords" content="Switches Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Switches Dealer in Tamil Nadu" />
        <meta name="keywords" content="Switches Distributor in Tamil Nadu" />

        <meta name="keywords" content="Switches Dealer in Bengaluru" />
        <meta name="keywords" content="Best Switches Dealer in Bengaluru" />
        <meta name="keywords" content="Switches Distributor in Bengaluru" />

        <meta name="keywords" content="Switches Dealer in Karnataka" />
        <meta name="keywords" content="Best Switches Dealer in Karnataka" />
        <meta name="keywords" content="Switches Distributor in Karnataka" />




        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseSwitchingSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Small Medium Business LAN & Wireless - Switching
      </div>
      <div className="switching-enterprise-subproduct-nav">
        <button
          className={`switching-enterprise-subproduct-nav-button ${currentSubproduct === 1
            ? "active-switching-enterprise-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Dlink
        </button>
        <button
          className={`switching-enterprise-subproduct-nav-button ${currentSubproduct === 2
            ? "active-switching-enterprise-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Edgecore
        </button>
        <button
          className={`switching-enterprise-subproduct-nav-button ${currentSubproduct === 3
            ? "active-switching-enterprise-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Aruba
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="switching-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="switching-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-switching-enterprise" : ""
                  }
                >
                  <button
                    className="switching-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseSwitchingSubcategoryPage;
