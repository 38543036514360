import React from "react";
import Karkinos from "../../EntertrpriseComponents/EnterpriseCaseStudyComponents/Karkinos";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../../Common Components/Whatsapp";
import { Helmet } from 'react-helmet';

function KarkinosCaseStudyPage() {
  return (
    <div>
      <Helmet>
        <title>Calidteh - Karkinos Case Study - HPE/Aruba, Palo Alto, Dell, Lenovo, Radmin, Altai Dealers Distributors in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Discover how Calid Technologies provided organization-wide consulting and set up the ICT infrastructure for Karkinos, utilizing advanced products like Cisco CBS350 series and Ruckus R650. Explore the comprehensive solutions and successful implementation." />
        <meta name="keywords" content="Advanced Networking Solutions,Calid Technologies Case Study,D-Link 1510-28X Setup,Network Infrastructure Design,IT Consulting Services,Karkinos Technology Solutions,Ruckus R650 Integration,Cisco CBS350 Series Deployment,ICT Infrastructure Setup,Organization-Wide IT Consulting" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Karkinos Case Study" />
        <meta property="og:description" content="Discover how Calid Technologies provided organization-wide consulting and set up the ICT infrastructure for Karkinos, utilizing advanced products like Cisco CBS350 series and Ruckus R650. Explore the comprehensive solutions and successful implementation." />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.calidtech.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/KarkinosCaseStudyPage" />
      </Helmet>
      <EnterpriseHeader />
      <Karkinos />
      <EnterpriseHeader />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default KarkinosCaseStudyPage;
