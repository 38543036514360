//ManufacturingWirelessPlanningSubservicePage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingWirelessPlanningSubservicePage.css";
import ManufacturingServicePageNavBar from "../ManufacturingServicePageNavBar";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import CoverageOptimization from "../../../assets/images/Coverage-Optimization.jpg";
import SpectrumManagement from "../../../assets/images/Spectrum-Management.jpg";
import CapacityPlanning from "../../../assets/images/Capacity-Planning.png";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import ContactButtons from "../../../Common Components/ContactButtons";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";


function ManufacturingWirelessPlanningSubservicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);

  return (
    <div className="manufacturing-service-page">

      <Helmet>
        <title>Calid Technologies - Wireless solution in Mumbai,pune,kolkata, chennai, Ahmedabad, delhi and across all Indian states</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com/ManufacturingWirelessPlanningSubservicePage" />
      </Helmet>


      <ManufacturingHeader />
      <ManufacturingServicePageNavBar />
      <header className="subservice-header-wireless">
        <div className="subheader-text">
          <h1 className="subservice-header-heading">
            <span id="subservice-header-subheading"> Wireless Planning Services
            Authorized Certified Partner and service provider for wifi network </span>
          </h1>
          <p className="subservice-header-description">
            Wireless planning involves the strategic design, implementation, and
            optimization of wireless communication networks to ensure efficient
            and reliable connectivity.
          </p>
          <div className="button-container">
            <button className="Downloadpdf">Get PDF</button>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>
      {/* header end */}

      <div className="manufacturing-servive-des">
        <h2 className="main-heading-entire-site">
          Optimization Strategy, Spectrum Efficiency, Connectivity Solution
        </h2>
        <p>
        Developing and implementing strategic plans to enhance the performance and efficiency of wireless systems and processes. Our services focus on utilizing available radio frequency spectrum in the most efficient manner to maximize communication capabilities. Calid Technologies provide robust and reliable solutions for seamless connectivity and data exchange, integrating advanced technologies such as IoT, 5G, and Wi-Fi to ensure uninterrupted, high-quality connectivity. Our Wireless Planning solutions are available across India, covering major cities like Mumbai, Delhi, Bangalore, Hyderabad, Chennai, Kolkata, Pune, and Ahmedabad, designed to support businesses with reliable, scalable wireless networks.
        </p>
      </div>

      <div className="subservice-card-div-container">
        <div className="subservice-card-div" style={{ "--clr": "#009688" }}>
          <div className="subservice-card-img-box">
            <img src={CoverageOptimization} alt="Coverage Optimization" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">
              Coverage Optimization
            </h2>
            <p className="subservice-card-div-description">
              Involves strategic planning and deployment of wireless
              infrastructure to ensure comprehensive network coverage,
              minimizing dead zones and signal disruptions while maximizing
              signal strength across targeted areas.
            </p>
          </div>
        </div>
        <div className="subservice-card-div" style={{ "--clr": "#FF3E7F" }}>
          <div className="subservice-card-img-box">
            <img src={SpectrumManagement} alt="Spectrum Management" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Spectrum Management</h2>
            <p className="subservice-card-div-description">
              This involves mitigating interference, optimizing frequency reuse,
              and complying with regulatory requirements to maximize spectrum
              efficiency and support the growing demand for wireless
              communication services without compromising quality of service.
            </p>
          </div>
        </div>
        <div className="subservice-card-div" style={{ "--clr": "#03A9F4" }}>
          <div className="subservice-card-img-box">
            <img src={CapacityPlanning} alt="Capacity Planning" />
          </div>
          <div className="subservice-card-div-content">
            <h2 className="subservice-card-div-heading">Capacity Planning</h2>
            <p className="subservice-card-div-description">
              This includes analyzing traffic patterns, user behavior, and
              application requirements to determine the optimal allocation of
              bandwidth, radio channels, and network infrastructure to ensure
              consistent performance and scalability while minimizing congestion
              and latency.
            </p>
          </div>
        </div>
        <div className="enterprise-servive-des">
        <Helmet>
        <meta name="description" content="we offer top-tier wireless network troubleshooting service across Pan India " />
        <meta name="keywords" content="Wireless Network, Network Integration, wireless designing, wireless planning " />
        </Helmet>
          
          {/* For wireless connection */}
         
        <h2 >
        &#128073; Is your WiFi Slow or having random disconnection issues? 

            {/* for wireless site survey */}
        </h2>
        <h2 >
        &#128073;Is your CEO complaining about WiFi slowness during conference call?
          
          {/* for wireless optimization */}
        </h2>
        <h2 >
        &#128073;Is your WiFi performance gets impacted during teams/G-Meet/Web-EX call?

        </h2>
        <h2 >
        &#128073;Are you facing WiFi disconnection issues during roaming?

        </h2>
        <h2 className="main-heading-entire-site">
        If any of the above is affecting your operations.

        </h2>
        <br></br>
        <div className="button-container">
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
            Get in touch for expert WiFi troubleshooting and network optimization solutions.
            </button>
          </div>
        

        
      </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
}

export default ManufacturingWirelessPlanningSubservicePage;
