//  ManufacturingIndustrialWiFiSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingIndustrialWiFiSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import AltaiAPA8 from "../../../assets/pdf/AP-A8.pdf";
import AltaiAX600 from "../../../assets/pdf/Altai-AX600.pdf";
import Aruba560EX from "../../../assets/pdf/Aruba-560EX.pdf";
import Aruba580 from "../../../assets/pdf/Aruba-580.pdf";
import CiscoCatalystIW9167 from "../../../assets/pdf/Cisco-Catalyst-IW9167.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";


const ManufacturingIndustrialWiFiSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Altai",
  //   2: "Aruba",
  //   3: "Cisco",
  // };

  const productDataList1 = [
    //Altai AP A8
    {
      images: [
        require("../../../assets/productImages/AP-A8-1.jpg"),
        require("../../../assets/productImages/AP-A8-2.png"),
        require("../../../assets/productImages/AP-A8-1.jpg"),
        require("../../../assets/productImages/AP-A8-2.png"),
      ],
      title: "Altai AP A8",
      about: (
        <>
          <p>
            Altai's super WiFi A8-Ein base station is an advanced 100º sectorial
            802.11 a/b/g/n/ac WiFi solution. With its integrated 8-element MIMO
            antenna array, the A8-Ein series is designed for fast, simple
            installation.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Altai AP A8 in Pune, Aurangabad, Nasik, Ahmednagar, Taloja, Shedung, Rabale, Kolhapur,Vapi, Halol, Vadodara, Bhavnagar, Surat,Bhilwara, Solan,Sambhalpur, Hosur, Belgaum. Contact us
            to avail best prices and discount for Altai AP A8.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Extended reach with up to a 30 km backhauling range</li>
          <li>50 cm co-location with 3G/4G antennas</li>
        </>
      ),
      downloadLink: AltaiAPA8,
      hyperlinks: [],
    },

    //Altai AX600
    {
      images: [
        require("../../../assets/productImages/Altai-AX600-1.png"),
        require("../../../assets/productImages/Altai-AX600-1.png"),
        require("../../../assets/productImages/Altai-AX600-1.png"),
        require("../../../assets/productImages/Altai-AX600-1.png"),
      ],
      title: "Altai AX600",
      about: (
        <>
          <p>
            Designed for medium to high-density outdoor venues, the Altai
            AX600-X delivers unparalleled Wi-Fi experience and highly reliable
            connectivity at the market's most affordable price point.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Altai AX600 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore. Contact us
            to avail best prices and discount for Altai AX600.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>
            MU MIMO significantly improves multi client capacity, and dual
            radios deliver data rates up to 1201 Mbps on 5 GHz and 574 Mbps on
            2.4 GHz
          </li>
        </>
      ),
      downloadLink: AltaiAX600,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //     //Aruba 560EX
    {
      images: [
        require("../../../assets/productImages/Aruba-560EX-1.png"),
        require("../../../assets/productImages/Aruba-560EX-1.png"),
        require("../../../assets/productImages/Aruba-560EX-1.png"),
        require("../../../assets/productImages/Aruba-560EX-1.png"),
      ],
      title: "Aruba 560EX",
      about: (
        <>
          <p>
            HPE Aruba Networking 560 series access points deliver cost-effective
            Wi-Fi 6 wireless connectivity in outdoor and environmentally
            challenging hazardous locations.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Aruba 560EX in Ahmednagar, Taloja, Shedung, Rabale, Kolhapur, Bhavnagar, Surat, Ahmedabad, Rajkot, Anand, patratu, Jamshedpur, Bengaluru, Rourkela, Sambhalpur, Bhubeneshwar, Cuttak, Mumbai. Contact us
            to avail best prices and discount for Aruba 560EX.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>1.5 Gbps maximum speed (HE80/HE20)</li>
          <li>Wi-Fi 6 for OFDMA and WPA3</li>
        </>
      ),
      downloadLink: Aruba560EX,
      hyperlinks: [],
    },

    //Aruba 580
    {
      images: [
        require("../../../assets/productImages/Aruba-580-1.png"),
        require("../../../assets/productImages/Aruba-580-1.png"),
        require("../../../assets/productImages/Aruba-580-1.png"),
        require("../../../assets/productImages/Aruba-580-1.png"),
      ],
      title: "Aruba 580",
      about: (
        <>
          <p>
            Exceptional performance with Wi-Fi 6 that's ideal for hazardous
            environments such as oil rigs, industrial manufacturing, and
            transportation sites.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Aruba 580 in Ahmednagar, Taloja, Shedung, Rabale, Kolhapur, Bhavnagar, Surat, Ahmedabad, Rajkot, Anand, patratu, Jamshedpur, Bengaluru, Rourkela, Sambhalpur, Bhubeneshwar, Cuttak, Mumbai. Contact us
            to avail best prices and discount for Aruba 580.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Automatic role-based access enforcement</li>
          <li>Wi-Fi 6 certification</li>
        </>
      ),
      downloadLink: Aruba580,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //Cisco Catalyst IW9167
    {
      images: [
        require("../../../assets/productImages/Cisco-Catalys-IW9167-3.jpg"),
        require("../../../assets/productImages/Cisco-Catalys-IW9167-1.jpg"),
        require("../../../assets/productImages/Cisco-Catalys-IW9167-3.jpg"),
        require("../../../assets/productImages/Cisco-Catalys-IW9167-2.jpg"),
      ],
      title: "Cisco Catalyst IW9167",
      about: (
        <>
          <p>
            For harsh conditions, remote assets, and demanding applications, you
            need wireless that pushes the boundaries. Choose Wi-Fi 6 or Cisco
            Ultra-Reliable Wireless Backhaul (Cisco URWB).
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Cisco Catalyst IW9167 in Ahmednagar, Taloja, Shedung, Rabale, Kolhapur, Bhavnagar, Surat, Ahmedabad, Rajkot, Anand, patratu, Jamshedpur, Bengaluru, Rourkela, Sambhalpur, Bhubeneshwar, Cuttak, Mumbai. Contact us
            to avail best prices and discount for Cisco Catalyst IW9167.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Improve speed and security with Wi-Fi 6</li>
          <li>Connect remote buildings or moving assets</li>
        </>
      ),
      downloadLink: CiscoCatalystIW9167,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="industrialWiFi-page">
      <Helmet>
        <title>Industrial WiFi - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta
          name="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Industrial WiFi Dealer in Pune" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Pune" />
        <meta name="keywords" content="Industrial WiFi Distributor in Pune" />

        <meta name="keywords" content="Industrial WiFi Dealer in Mumbai" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Mumbai" />
        <meta name="keywords" content="Industrial WiFi Distributor in Mumbai" />

        <meta name="keywords" content="Industrial WiFi Dealer in Maharashtra" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Maharashtra"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Maharashtra"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Vadodara" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Vadodara"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Vadodara"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Surat" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Surat" />
        <meta name="keywords" content="Industrial WiFi Distributor in Surat" />

        <meta name="keywords" content="Industrial WiFi Dealer in Ahmedabad" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Ahmedabad"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Ahmedabad"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Indore" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Indore" />
        <meta name="keywords" content="Industrial WiFi Distributor in Indore" />

        <meta name="keywords" content="Industrial WiFi Dealer in Jaipur" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Jaipur" />
        <meta name="keywords" content="Industrial WiFi Distributor in Jaipur" />

        <meta name="keywords" content="Industrial WiFi Dealer in Noida" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Noida" />
        <meta name="keywords" content="Industrial WiFi Distributor in Noida" />

        <meta name="keywords" content="Industrial WiFi Dealer in Gurugram" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Gurugram"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Gurugram"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Delhi" />
        <meta name="keywords" content="Best Industrial WiFi Dealer in Delhi" />
        <meta name="keywords" content="Industrial WiFi Distributor in Delhi" />

        <meta name="keywords" content="Industrial WiFi Dealer in Kolkata" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Kolkata"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Kolkata"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Bhubaneswar" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Bhubaneswar"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Bhubaneswar"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Hyderabad" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Hyderabad"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Hyderabad"
        />

        <meta
          name="keywords"
          content="Industrial WiFi Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Visakhapatnam"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Chennai" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Chennai"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Chennai"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Coimbatore" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Coimbatore"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Coimbatore"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Bengaluru" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Bengaluru"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Bengaluru"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Tamil Nadu" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Tamil Nadu"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Tamil Nadu"
        />

        <meta name="keywords" content="Industrial WiFi Dealer in Karnataka" />
        <meta
          name="keywords"
          content="Best Industrial WiFi Dealer in Karnataka"
        />
        <meta
          name="keywords"
          content="Industrial WiFi Distributor in Karnataka"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta
          name="twitter:description"
          content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://calidtech.com/ManufacturingIndustrialWiFiSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Communication And Computing - Industrial WiFi
      </div>
      <div className="industrialWiFi-subproduct-nav">
        <button
          className={`industrialWiFi-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-industrialWiFi-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Altai
        </button>
        <button
          className={`industrialWiFi-subproduct-nav-button ${
            currentSubproduct === 2 ? "active-industrialWiFi-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(2)}
        >
          Aruba
        </button>
        <button
          className={`industrialWiFi-subproduct-nav-button ${
            currentSubproduct === 3 ? "active-industrialWiFi-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(3)}
        >
          Cisco
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="industrialWiFi-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-industrialWiFi-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-industrialWiFi" : ""
                  }
                >
                  <button
                    className="industrialWiFi-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingIndustrialWiFiSubcategoryPage;
