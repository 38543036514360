// EnterpriseWirelessPlanningServicesSubservicePage.js

import React, { useState } from "react";
import "./EnterpriseWirelessPlanningServicesSubservicePage.css";
import EnterpriseServicePageNavBar from "../EnterpriseServiceNavbarPage";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import SiteSurveysAndAnalysis from "../../../assets/images/SiteSurveysAndAnalysis.png";
import CapacityPlanning from "../../../assets/images/CapacityPlanning.jpg";
import InterferenceMitigationStrategies from "../../../assets/images/InterferenceMitigationStrategies.jpg";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";




function EnterpriseWirelessPlanningServicesSubservicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);
  // const handleScrollTo = (elementId) => {
  //   const element = document.getElementById(elementId);
  //   if (element) {
  //     if (!("scrollBehavior" in document.documentElement.style)) {
  //       smoothScrollTo(element.offsetTop, 600);
  //     } else {
  //       element.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }
  //   }
  // };

  // Smooth scroll function

  // const smoothScrollTo = (endX, duration) => {
  //   const startX = window.scrollY || window.pageYOffset;
  //   const distanceX = endX - startX;
  //   const startTime = new Date().getTime();

  //   const easeInOutQuart = (time, from, distance, duration) => {
  //     if ((time /= duration / 2) < 1)
  //       return (distance / 2) * time * time * time * time + from;
  //     return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  //   };

  //   const timer = setInterval(() => {
  //     const time = new Date().getTime() - startTime;
  //     const newX = easeInOutQuart(time, startX, distanceX, duration);
  //     if (time >= duration) {
  //       clearInterval(timer);
  //     }
  //     window.scroll(0, newX);
  //   }, 1000 / 10); // 60 fps
  // };

  return (
    <div className="enterprise-service-page">
      <Helmet>
        <title>Calid Technologies - Enterprise Wireless Planning Services - Juniper Mist, HPE/ARUBA, Ruckus, Cisco, Switches, Wireless Access Point, HPE Server, Dell Server, Dell Storage, Lenovo Server, Palo Alto Firewall, FortiGate, FortiGate Firewall, Sophos Firewall, Captive Portal Server, Cambium Network Products, Radwin Products, Mimosa Networks Products, Altai Products - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseWirelessPlanningServicesSubservicePage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseServicePageNavBar />
      <header className="enterprise-subservice-header-wireless">
        <div className="enterprise-subheader-text">
          <h1 className="enterprise-subservice-header-heading">
            <span id="enterprise-subservice-header-subheading">
              {" "}
              Wireless Planning Services{" "}
              <h1 className="enterprise-subservice-header-heading" >Authorized Certified Partner and service provider for wifi network</h1>
              {/* <h1 className="enterprise-subservice-header-heading ">Ekahau,airmagnet,sidekick,actig wireless planning,predictive planning</h1> */}
            </span>
          </h1>
          <p className="enterprise-subservice-header-description">
            Wireless planning services involve the strategic design,
            implementation, and optimization of wireless communication networks
            to ensure optimal coverage, performance, and reliability.
          </p>
          <div className="button-container">
            <button className="Downloadpdf">Get PDF</button>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>
      {/* header end */}

      <div className="enterprise-servive-des">
        <h2 className="main-heading-entire-site">
          Strategic Wireless Solutions, Network Optimization Services,
          Customized Connectivity Solutions
        </h2>
        <p>
        At Calid Technologies, we offer top-tier wireless network troubleshooting service across Pan India. Our  network optimization services boost performance, while strategic wireless solutions ensure seamless wireless connectivity in the wireless LA. With fast wireless network troubleshooting and tailored customized connectivity solutions, we keep your business running smoothly with reliable and secure network infrastructure. Experience unmatched efficiency and innovation with us across India.
        </p>
      </div>

      <div className="enterprise-subservice-card-div-container">
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#009688" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img src={SiteSurveysAndAnalysis} alt="Site Surveys and Analysis" />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Site Surveys and Analysis
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Conducting thorough site surveys and spectrum analysis to assess
              environmental factors and identify potential sources of
              interference.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#FF3E7F" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img src={CapacityPlanning} alt="Capacity Planning" />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Capacity Planning
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Determining the optimal allocation of network resources, including
              bandwidth, channels, and access points, to support current and
              future user demands without sacrificing performance or
              reliability.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#03A9F4" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img
              src={InterferenceMitigationStrategies}
              alt="Interference Mitigation Strategies"
            />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
              Optimization
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Implementing advanced techniques and technologies to mitigate
              interference from neighboring networks, electronic devices, and
              physical obstructions.
            </p>
          </div>
        </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      <div className="enterprise-servive-des">
        <Helmet>
        <meta name="description" content="we offer top-tier wireless network troubleshooting service across Pan India " />
        <meta name="keywords" content="Wireless Network, Network Integration, wireless designing, wireless planning " />
        </Helmet>
          
          {/* For wireless connection */}
         
        <h2 >
        &#128073; Is your WiFi Slow or having random disconnection issues? 

            {/* for wireless site survey */}
        </h2>
        <h2 >
        &#128073;Is your CEO complaining about WiFi slowness during conference call?
          
          {/* for wireless optimization */}
        </h2>
        <h2 >
        &#128073;Is your WiFi performance gets impacted during teams/G-Meet/Web-EX call?

        </h2>
        <h2 >
        &#128073;Are you facing WiFi disconnection issues during roaming?

        </h2>
        <h2 className="main-heading-entire-site">
        If any of the above is affecting your operations.

        </h2>
        <br></br>
        <div className="button-container">
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
            Get in touch for expert WiFi troubleshooting and network optimization solutions.
            </button>
          </div>
        

        
      </div>
      
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default EnterpriseWirelessPlanningServicesSubservicePage;
