import React from "react";
import { Link } from "react-router-dom";
import "./ManufacturingFooter.css";
import footerLogo from "../../assets/images/footerLogo.png";
import { FaLinkedin, FaInstagram } from 'react-icons/fa';


const ManufacturingFooter = () => {
  return (
    <div className="footer-part-of-page">
      <footer id="footer-4-cols" class="site-footer">
        <div id="footer-container">
          <div id="footer-grid">
            <div id="left-footer-section" class="footer-section">
              <div class="footer-information">
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/marker.png" />
                  JP North, Behind Western Hotel, WEH,Mira Road (E), 401107
                </span>
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/phone.png" />
                  7977584476
                </span>
                <span>
                  <img src="https://img.icons8.com/ios-filled/12/999999/mail.png" />
                  <a href="mailto:info@calidtech.com">info@calidtech.com</a>
                </span>
              </div>
            </div>

            <div id="first-footer-section" class="footer-section">
              <div class="footer-tags">
                <h3>Tags</h3>
                <a href="#" class="footer-tag">
                  Anybus
                </a>
                <a href="#" class="footer-tag">
                  Teltonika
                </a>
                <a href="#" class="footer-tag">
                  Dalnex
                </a>
                <a href="#" class="footer-tag">
                  Moxa
                </a>
                <a href="#" class="footer-tag">
                  Smart IOT Gateway
                </a>
                <a href="#" class="footer-tag">
                  Warehouse Management
                </a>
                <a href="#" class="footer-tag">
                  Industrial Switches
                </a>
                <a href="#" class="footer-tag">
                  Industrial Routers
                </a>
                <a href="#" class="footer-tag">
                  Industrial Wifi
                </a>
                <a href="#" class="footer-tag">
                  EWON
                </a>
                <a href="#" class="footer-tag">
                  Advantech
                </a>
                <a href="#" class="footer-tag">
                  Modbus
                </a>
                <a href="#" class="footer-tag">
                  MQTT
                </a>
                <a href="#" class="footer-tag">
                  OT Security
                </a>
                <a href="#" class="footer-tag">
                  Protocol Converter
                </a>
                <a href="#" class="footer-tag">
                  Smart Metering
                </a>
                <a href="#" class="footer-tag">
                  IoT Custom Development
                </a>
              </div>
            </div>
            <div id="second-footer-section" class="footer-section">
              <div class="footer-links">
                <h3>Categories</h3>
                <ul>
                  <li>
                    <a href="#">
                      <Link to="/ManufacturingIndustrialEdgeConnectivityCategoryPage">
                        Industrial Edge Connectivity
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/ManufacturingIndustrialCommunicationAndComputingCategoryPage">
                        Industrial communication and computing
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/ManufacturingAnalyticsCategoryPage">
                        Analytics
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link to="/ManufacturingOTSecurityCategoryPage">
                        OT Security
                      </Link>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="right-footer-section" class="footer-section">
              <div class="footer-links">
                <h3>Information</h3>
                <ul>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/ManufacturingLandingPage">
                        Home
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/ManufacturingProductPage">
                        Products
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/ManufacturingServicePage">
                        Service
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/ManufacturingCaseStudyPage">
                        Case Studies
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/JobBoard">
                        Job Openings
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Link className="a-link" to="/ManufacturingContactUs">
                        Leave a Feedback
                      </Link>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="copyright-information">
            <div id="footer-logo-section">
              <div id="footer-logo">
                <img src={footerLogo} alt="footer-logo" />
              </div>
            </div>
            <div id="copyright-text">
              &copy; Calid Technologies. All rights reserved.
            </div>
            
            {/* Social Media Links */}
            <div id="social-buttons">
              <a href="https://www.linkedin.com/company/calid-technologies/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} style={{ margin: "0 10px", color: "#0e76a8" }} />
              </a>
              <a href="https://www.instagram.com/invites/contact/?igsh=n0ysbc6ko31e&utm_content=wi7cp1l" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} style={{ margin: "0 10px", color: "#C13584" }} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ManufacturingFooter;
