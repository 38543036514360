import React, { useState, useEffect } from "react";
import "./ContactUsBot.css";

const ContactUsBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsOpen(true);
    }, 45000);

    return () => clearInterval(interval);
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setSubmitted(false);
    }
  };

  const handleUserInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();

    const formUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLScCh_Wn_mi8GlNCFJg0xw82iFGq9m3a7Z2STUBKRA2sSh9TKQ/formResponse";
    const formDataToSend = {
      "entry.1812720550": formData.name,
      "entry.1870394174": formData.email,
      "entry.97349512": formData.phone,
      "entry.231532781": formData.message,
    };

    try {
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formDataToSend).toString(),
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="contact-bot-container">
      <button className="chat-icon" onClick={handleToggle}>
        {isOpen ? "Minimize" : "Chat with us"}
      </button>
      {isOpen && (
        <div className="chatbox open">
          <div className="chat-header">
            <div className="chat-title">Contact Us</div>
            <button
              className="close-button contactBot"
              onClick={handleToggle}
              style={{ color: "white", background: "none" }}
            >
              Close
            </button>
          </div>
          <div className="chat-messages">
            {submitted ? (
              <div className="message bot">Thank you for contacting us!</div>
            ) : (
              <form onSubmit={handleUserSubmit} className="message-form open">
                <label htmlFor="name" style={{ color: "black" }}>
                  Please Provide the following details:
                </label>
                {/* <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleUserInput}
                  placeholder="Your Name"
                  required
                />

                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleUserInput}
                  placeholder="Your Email"
                  required
                /> */}

                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleUserInput}
                  placeholder="Your Phone"
                  required
                />

                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleUserInput}
                  placeholder="Your Message"
                  required
                ></textarea>

                <button
                  className="SubmitButton"
                  type="submit"
                  style={{ color: "White" }}
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUsBot;
