import React from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import TataCommunications from "../../ManufacturingComponents/ManufacturingCaseStudyComponents/TataCommunications";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import Whatsapp from "../../../Common Components/Whatsapp";

function TataCommunicationsCaseStudyPage() {
  return (
    <div>
      <Helmet>
        <title>Calid Technologies - Tata Communications Case Study - Helmholz, Anybus, Dalnex, Teltonika Dealer Distributor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring." />
        <meta name="keywords" content="Smart Meter Benefits,Utility Management Systems,Real-time Energy Monitoring,Energy Efficiency Tools,Automated Meter Reading,Smart Grid Solutions,Utility Management SystemsMeter Data Management,Advanced Metering Infrastructure,Smart Metering Systems,Energy Metering Solutions,AMR Technology,Energy Efficiency Tools,Real-time Energy Monitoring,Energy Efficiency Tools,Tata Communications Case Study,Smart Energy meter supplier in mumbai ,pune and All India." />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta name="twitter:description" content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />
        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseServicePage" />
      </Helmet>
      <ManufacturingHeader />
      <TataCommunications />
      <ManufacturingHeader />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
}

export default TataCommunicationsCaseStudyPage;
