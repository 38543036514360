import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "../Enterprise/EntertrpriseComponents/EnterpriseHeader.css";
import "./JobPosting.css";
import Whatsapp from "../Common Components/Whatsapp";
import ContactUsBot from "../Common Components/ContactUsBot";
import JobPostingHeader from "./JobComponents/JobPostingHeader";
import JobPostingFooter from "./JobComponents/JobPostingFooter";

const ICCCJob = ({ siteName }) => {
  const job = {
    title: "ICCC Sr. Associate",
    location: "Mumbai",
    jobType: "Permanent",
    // description: 'Join our team as a frontend developer...',
    skills: [
      "- Graduate with minimum 2+ years of experience in a technical call center or an integrated commend center is desired.",
      "- Applicant should be well versed with new age technologies around smart cities and should be able to understand and extract info from several GUIs.",
      "- Must be proficient with excel and needs to have good communication skills",
      "- This will not be an end user facing opportunity but more of an Onsite team enabler and manager.",
    ],
  };

  return (

    <div className="jobDiv">


      <Helmet>
        <title>Calid Technologies - ICCC Job Opening</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com" />
      </Helmet>

      <div className="job-posting-parent">
        <div className="job-posting">
          <div className="job-header">
            <h1 className="job-title">{job.title}</h1>
          </div>
          <div className="job-details">
            <p className="detail">
              <strong>Location:</strong> {job.location}
            </p>
            <p className="detail">
              <strong>Job Type:</strong> {job.jobType}
            </p>
            <a
              href="https://forms.gle/aLBY1pbRpqv3Nizc7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="apply-button">Apply Now</button>
            </a>
          </div>
          {/* <div className="job-description">
                        <h2>Description:</h2>
                        <p>{job.description}</p>
                    </div> */}
          <div className="job-skills">
            <h2>Skills Required:</h2>
            <ul>
              {job.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* <ManufacturingHeader /> */}

      <JobPostingHeader />
      <Whatsapp />
      <ContactUsBot />
      <JobPostingFooter />
    </div>
  );
};

export default ICCCJob;
