import React, { useState, useEffect } from "react";
import "./WhatsapPopup.css";

const WhatsapPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const phoneNumber = event.target.elements.phoneNumber.value;
    if (!/^\d{10}$/.test(phoneNumber)) {
      setErrorMessage("Please enter a valid 10-digit phone number.");
      return;
    }

    // New Google Form URL
    const formUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLScCh_Wn_mi8GlNCFJg0xw82iFGq9m3a7Z2STUBKRA2sSh9TKQ/formResponse";

    // Create form data object
    const formData = new FormData();
    formData.append("entry.97349512", phoneNumber); // Replace this with the correct entry ID for the phone number
    formData.append("entry.1812720550", "I have left you my number. Please contact me."); // Name field
    formData.append("entry.1870394174", "I have left you my number. Please contact me."); // Email field
    formData.append("entry.231532781", "I have left you my number. Please contact me."); // Message field

    try {
      // Submit the form data to the Google Form
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors", // Important to prevent CORS issues
        body: formData,
      });
      console.log(`Phone number submitted: +91-${phoneNumber}`);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setIsVisible(false);
      }, 3000);
    } catch (error) {
      console.error("Error submitting the form", error);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="whatsapp-popup-overlay">
      <div className="whatsapp-popup">
        <button className="whatsapp-close-button" onClick={handleClose}>
          ×
        </button>
        {showSuccessMessage ? (
          <p className="success-message">
            Thank you for providing your contact number. We will contact you
            shortly.
          </p>
        ) : (
          <>
            <h2>Let us know how we can help</h2>
            <form className="whatsapp-popup-input" onSubmit={handleSubmit}>
              <div className="phone-input">
                <span className="country-code">+91-</span>
                <input type="text" name="phoneNumber" required />
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <button type="submit">Submit</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default WhatsapPopup;