// ManufacturingBlackbearSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ManufacturingBlackbearSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import DD9000 from "../../../assets/pdf/DD9000.pdf";
import BIG9000 from "../../../assets/pdf/BIG9000.Pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";


const ManufacturingBlackbearSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //BIG9000
    {
      images: [
        require("../../../assets/productImages/BIG9000-1.png"),
        require("../../../assets/productImages/BIG9000-1.png"),
        require("../../../assets/productImages/BIG9000-1.png"),
        require("../../../assets/productImages/BIG9000-1.png"),
      ],
      title: " Blackbear Cybersecurity gateway BIG9000",
      about: (
        <>
          <p>
          Blackbear Cybersecurity gateway BIG9000 is a unidirectional communication and data transfer gateway
            that secures your critical operation assets across the Layer-1
            network. Its security design prevents data leakage.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Blackbear Cybersecurity gateway BIG9000 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore, Vapi, Halol, Nimrana
            . Contact us to avail best prices
            and discount for Blackbear Cybersecurity gateway BIG9000.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>A rugged unidirectional gateway.</li>
          <li> Cyber Security at OSI Layer-1 for your asset.</li>
        </>
      ),
      downloadLink: BIG9000,
      hyperlinks: [],
    },

    //DD9000
    {
      images: [
        require("../../../assets/productImages/DD9000-1.png"),
        require("../../../assets/productImages/DD9000-1.png"),
        require("../../../assets/productImages/DD9000-1.png"),
        require("../../../assets/productImages/DD9000-1.png"),
      ],
      title: "Blackbear Cybersecurity gateway DD9000",
      about: (
        <>
          <p>
          Blackbear Cybersecurity gateway DD9000 is a unidirectional communication and data transfer gateway
            that secures your critical operation assets across the Layer-1
            network. Its security design prevents data leakage.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Blackbear Cybersecurity gateway DD9000 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore. Contact us to avail best prices
            and discount for Blackbear Cybersecurity gateway DD9000.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>We protect from outside dangers.</li>
          <li>We protect from new threats.</li>
        </>
      ),
      downloadLink: DD9000,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blackbear-page">
      <Helmet>
        <title>Blackbear Gateway - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calid Technologies. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India" />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation,India OT security solutions,Pune industrial solutions,Mumbai manufacturing security,Calid Technologies Blackbear products,Industrial monitoring,OT monitoring,Manufacturing security solutions,Industrial cybersecurity,Condition monitoring systems,Blackbear BIG9000,Blackbear DD9000,India OT security solutions,Pune industrial solutions,Mumbai manufacturing security" />
        <meta
          name="description"
          content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calid Technologies. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India"
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation,India OT security solutions,Pune industrial solutions,Mumbai manufacturing security,Calid Technologies Blackbear products,Industrial monitoring,OT monitoring,Manufacturing security solutions,Industrial cybersecurity,Condition monitoring systems,Blackbear BIG9000,Blackbear DD9000,India OT security solutions,Pune industrial solutions,Mumbai manufacturing security"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Blackbear Gateway Dealer in Pune" />
        <meta name="keywords" content="Best Blackbear Gateway Dealer in Pune" />
        <meta name="keywords" content="Blackbear Gateway Distributor in Pune" />

        <meta name="keywords" content="Blackbear Gateway Dealer in Mumbai" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Mumbai"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Mumbai"
        />

        <meta
          name="keywords"
          content="Blackbear Gateway Dealer in Maharashtra"
        />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Maharashtra"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Maharashtra"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Vadodara" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Vadodara"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Vadodara"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Surat" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Surat"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Surat"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Ahmedabad" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Ahmedabad"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Ahmedabad"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Indore" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Indore"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Indore"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Jaipur" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Jaipur"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Jaipur"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Noida" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Noida"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Noida"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Gurugram" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Gurugram"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Gurugram"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Delhi" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Delhi"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Delhi"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Kolkata" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Kolkata"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Kolkata"
        />

        <meta
          name="keywords"
          content="Blackbear Gateway Dealer in Bhubaneswar"
        />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Bhubaneswar"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Bhubaneswar"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Hyderabad" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Hyderabad"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Hyderabad"
        />

        <meta
          name="keywords"
          content="Blackbear Gateway Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Visakhapatnam"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Chennai" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Chennai"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Chennai"
        />

        <meta
          name="keywords"
          content="Blackbear Gateway Dealer in Coimbatore"
        />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Coimbatore"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Coimbatore"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Bengaluru" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Bengaluru"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Bengaluru"
        />

        <meta
          name="keywords"
          content="Blackbear Gateway Dealer in Tamil Nadu"
        />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Tamil Nadu"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Tamil Nadu"
        />

        <meta name="keywords" content="Blackbear Gateway Dealer in Karnataka" />
        <meta
          name="keywords"
          content="Best Blackbear Gateway Dealer in Karnataka"
        />
        <meta
          name="keywords"
          content="Blackbear Gateway Distributor in Karnataka"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calid Technologies. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India"
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FDT Blackbear" />
        <meta
          name="twitter:description"
          content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calid Technologies. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India"
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Secure your operations with OT Security solutions from Blackbear, including DD9000 and BIG9000, at Calid Technologies. Enhance manufacturing safety and efficiency with advanced monitoring systems. Serving Mumbai, Pune, and across India"
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://www.calidtech.com/ManufacturingBlackbearSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        OT Security - Blackbear
      </div>
      <div className="blackbear-subproduct-nav">
        <button
          className={`blackbear-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-blackbear-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="blackbear-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-blackbear-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-blackbear" : ""}
                >
                  <button
                    className="blackbear-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingBlackbearSubcategoryPage;
