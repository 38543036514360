import React, { useState, useEffect } from "react";

const ContactPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 7 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 12000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => setShowPopup(false);

  return (
    <>
      {showPopup && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <button style={styles.closeButton} onClick={closePopup}>
              ✖
            </button>
            <h2 style={styles.heading}>Hey there! 👋</h2>
            <p style={styles.message}>
              Need help or want to chat? I’m just a call or email away! Let’s
              connect. 😊
            </p>
            {/* Call Button */}
            <button
              style={{ ...styles.button, ...styles.callButton }}
              onClick={() => (window.location.href = "tel:8828615492")}
            >
              📞 Call us Now
            </button>
            {/* Mail Button */}
            <button
              style={{ ...styles.button, ...styles.mailButton }}
              onClick={() => (window.location.href = "mailto:info@calidtech.com")}
            >
              ✉️ Send us an Email
            </button>
          </div>
        </div>
      )}
    </>
  );
};

// Styles
const styles = {
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    position: "relative",
    textAlign: "center",
    padding: "30px",
    borderRadius: "15px",
    backgroundColor: "#fff",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
    animation: "fadeIn 0.5s ease-in-out",
    width: "90%",
    maxWidth: "400px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "10px",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    color: "#444",
  },
  message: {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#555",
    fontStyle: "italic",
  },
  button: {
    display: "block",
    width: "80%",
    padding: "15px",
    margin: "10px auto",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
  },
  callButton: {
    backgroundColor: "#4CAF50",
    color: "#fff",
  },
  mailButton: {
    backgroundColor: "#2196F3",
    color: "#fff",
  },
};

// Keyframes for fading animation
const globalStyles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

// Inject global styles for animation
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);

export default ContactPopup;
