import React, { useState, useRef } from "react";
import "./EnterpriseCaseStudy.css";
import "../../../Common Components/Hero.css";
import AxxelaHealthcareCaseStudy from "../../../assets/pdf/AxxelaHealthcareCaseStudy.pdf";
import CaseStudyIndividualPage from "../../../assets/images/CaseStudyIndividualPage.jpg";
import ProductsLinked from "../../../Common Components/ProductsLinked";

const Axxela = () => {
  const products = [
    {
      id: 1,
      name: "Cisco C9200-24T-A",
      image: "path/to/image1.jpg",
      link: "/enterprise/cisco/c9200",
    },
    {
      id: 2,
      name: "Cisco C9300-48T-E",
      image: "path/to/image2.jpg",
      link: "http://example.com/product2",
    },
    {
      id: 3,
      name: "Cambium E510",
      image: "path/to/image3.jpg",
      link: "http://example.com/product3",
    },
    {
      id: 4,
      name: "Cisco CBS350-24T",
      image: "path/to/image2.jpg",
      link: "http://example.com/product2",
    },
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const emailInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userEmail = emailInputRef.current.value;

    const formUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLScCh_Wn_mi8GlNCFJg0xw82iFGq9m3a7Z2STUBKRA2sSh9TKQ/formResponse";

    const formData = new FormData();
    formData.append("entry.1870394174", userEmail); // Email field
    formData.append("entry.1812720550", "Axxela case study downloaded");
    formData.append("entry.97349512", "Axxela case study downloaded");
    formData.append("entry.231532781", "Axxela case study downloaded");

    try {
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      });

      setShowPopup(false);
      setShowDownloadPopup(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowDownloadPopup(false);
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = AxxelaHealthcareCaseStudy;
    link.setAttribute("download", "Axxela Healthcare Case Study.pdf");
    link.click();
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
    setShowDownloadPopup(false);
  };

  return (
    <div className="Individual-CaseStudy-Div">
      <div
        className="ImageBackgroundWrap Background-fixed"
        style={{
          backgroundImage: `url(${CaseStudyIndividualPage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="ImageInnerWrap"></div>
      </div>

      <div className="headline" id="CaseStudyDv">
        <h1>Axxela Research and Analytics</h1>
      </div>
      <div className="case-study-div">
        <div className="case-study">
          <div className="case-study-table">
            <table>
              <tbody>
                <tr>
                  <td className="table-label">Assignment name:</td>
                  <td>Data Centre Network and office LAN infra set up</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Approx. value of the contract:
                  </td>
                  <td>INR 15,00,000</td>
                </tr>
                <tr>
                  <td className="table-label">Country:</td>
                  <td>India</td>
                </tr>
                <tr>
                  <td className="table-label">Location within country:</td>
                  <td>Mumbai, Noida, Bangalore and Kolkata</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Duration of assignment (months):
                  </td>
                  <td>1</td>
                </tr>
                <tr>
                  <td className="table-label">Name of client:</td>
                  <td>Axxela Research and Analytics</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Total No of staff-months of the assignment:
                  </td>
                  <td>2</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Name of senior professional staff of your firm involved and
                    functions performed:
                  </td>
                  <td>
                    Mr. Abhijeet Ghosh – Senior Technical Consultant Mr.
                    Abhishek Trivedi – Lead Technical Consultant
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button className="read-more-btn" onClick={handleOpenPopup}>
            Read More
          </button>
          {showPopup && (
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
              <p className="popup-message">
                Want to read more of this case study? Please give us your email:
              </p>
              <form onSubmit={handleSubmit} className="email-form">
                <input
                  type="email"
                  ref={emailInputRef}
                  name="email"
                  placeholder="Enter your email"
                  required
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
          {showDownloadPopup && (
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
              <p>
                Thank you for your email. Your case study PDF is ready for
                download:
              </p>
              <div className="download-button-container">
                <button className="download-btn" onClick={handleDownloadPDF}>
                  Download PDF
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ProductsLinked products={products} />
    </div>
  );
};

export default Axxela;
