import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import './DividingPage.css';
import button1 from './assets/images/EnterpriseLandingPage.jpg';
import button2 from './assets/images/factoryDigital.jpg';
import EntDivingIcons from './EntDividingIcons';
import ManuDividingIcons from './ManuDividingIcons';
import CallButton from './Common Components/CallButton';



const DividingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Calid Technologies</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        {/* <meta property="fb:app_id" content="Your Facebook App ID" /> */}

        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content="Calid Technologies" /> */}
        {/* <meta name="twitter:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." /> */}
        {/* <meta name="twitter:image" content="https://calidtech.com/twitter-image.jpg" /> */}
        {/* <meta name="twitter:site" content="@CalidTech" /> */}
        {/* <meta name="twitter:creator" content="@CalidTech" /> */}

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <link rel="icon" href={favicon} /> */}
        {/* <link rel="apple-touch-icon" href="/apple-touch-icon.png" /> */}
        {/* <link rel="manifest" href={manifest} /> */}
        <link rel="canonical" href="https://calidtech.com" />
      </Helmet>
      <div className="blur-background"></div>

      <div class="dividing-logo-text">
        <h2>  CALID TECHNOLOGIES </h2>
      </div>
      <EntDivingIcons />
      <ManuDividingIcons />
      <div className="oblique">
        <div className="main-block-oblique skew-block">
          <div className="skew-block-repeat">
            <Link to="/EnterpriseLandingPage">
              <div className="oblique-inner">
                <div className="image-wrapper">
                  <div className="main-image">
                    <img className="image-img" src={button1} alt="Entry 1" />
                  </div>
                </div>
                <div className="oblique-caption caption-top">
                  <h2>Enterprise <br /> Solutions</h2>
                  <button>Discover</button>
                </div>
              </div>
            </Link>
          </div>
          <div className="skew-block-repeat">
            <Link to="/ManufacturingLandingPage">
              <div className="oblique-inner">
                <div className="image-wrapper">
                  <div className="main-image">
                    <img className="image-img" src={button2} alt="Entry 2" />
                  </div>
                </div>
                <div className="oblique-caption caption-bottom">
                  
                  <h2>Factory Digital <br /> Transformations</h2>
                  <button>Discover</button>
                </div>
                
              </div>
              
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DividingPage;
