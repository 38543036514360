// EnterpriseHPSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseHPSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import HPML30 from "../../../assets/pdf/HP-ML30.pdf";
import HPM350 from "../../../assets/pdf/HP-ML350.pdf";
import HPML110 from "../../../assets/pdf/HP-ML110.pdf";
import DL20 from "../../../assets/pdf/DL20.pdf";
import DL360 from "../../../assets/pdf/DL360.pdf";
import DL380 from "../../../assets/pdf/DL380.pdf";
import DL365 from "../../../assets/pdf/DL365.pdf";
import NAS1660 from "../../../assets/pdf/NAS-storeeasy-1660.pdf";
import HPEMSA2060 from "../../../assets/pdf/HPE-MSA-2060.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";



const EnterpriseHPSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Tower and Rackmount Servers",
  //   2: "Storage",
  // };

  const productDataList1 = [
    //HP ML30
    {
      images: [
        require("../../../assets/productImages/HP-ML30-1.png"),
        require("../../../assets/productImages/HP-ML30-2.png"),
        require("../../../assets/productImages/HP-ML30-3.png"),
        require("../../../assets/productImages/HP-ML30-4.png"),
      ],
      title: "HPE ML30",
      about:(
        <>
        <p>The HPE ProLiant ML30 Gen11 Plus server is a powerful yet affordable tower server designed for small offices, remote and branch offices to run on-premises and hybrid cloud solutions.
          </p>
          <br></br>
          <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE ML30 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai and Pune. Contact us to avail best prices and discount for HPE ML30.</p>
          
          </>
      ),
      enterpriseFeature: (
        <>
          <li>Supports Intel® Xeon® E processors up to 8 cores 95W.</li>
          <li>Onboard M.2 NVMe SSD support.</li>
          <li>Dedicated HPE iLO port and serial port kit option.</li>
        </>
      ),
      downloadLink: HPML30,
      hyperlinks: [],
    },

    //HP ML350
    {
      images: [
        require("../../../assets/productImages/HP-ML350-1.png"),
        require("../../../assets/productImages/HP-ML350-2.png"),
        require("../../../assets/productImages/HP-ML350-3.png"),
        require("../../../assets/productImages/HP-ML350-4.png"),
      ],
      title: "HP ML350",
      about:(
        <>
        <p>HPE ProLiant ML350 Gen10 server delivers a secure dual-socket tower server with performance, expandability, and proven reliability making it the choice for expanding SMBs.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HP ML350 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for HP ML350.</p>
        
        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Supports the additional 2nd generation Intel® Xeon Scalable
            Processor offerings delivering exceptional customer value with
            increased performance and industry leading frequency.
          </li>
        </>
      ),
      downloadLink: HPM350,
      hyperlinks: [],
    },

    //HP ML110
    {
      images: [
        require("../../../assets/productImages/HP-ML110-1.png"),
        require("../../../assets/productImages/HP-ML110-2.png"),
        require("../../../assets/productImages/HP-ML110-3.png"),
        require("../../../assets/productImages/HP-ML110-4.png"),
      ],
      title: "HP ML110",
      about:(
        <>
        <p>The HPE ProLiant ML110 Gen11 server is an enhanced single processor tower with performance, expansion, and security at an affordable price. It supports the 4th and 5th Gen Intel® Xeon® Scalable processor.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HP ML110 inPune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for HP ML110.</p>
        

        </>
              ),
      enterpriseFeature: (
        <>
          <li>
            Increased memory bandwidth to 5600 MT/s1 , performance and lower
            power requirements with up to 1.5 TB DDR5 memory capacity with 16
            DIMM slots.
          </li>
        </>
      ),
      downloadLink: HPML110,
      hyperlinks: [],
    },

    //DL20
    {
      images: [
        require("../../../assets/productImages/DL20-1.png"),
        require("../../../assets/productImages/DL20-2.png"),
        require("../../../assets/productImages/DL20-3.png"),
        require("../../../assets/productImages/DL20-4.png"),
      ],
      title: "HPE DL20",
      about:(
        <>
        <p> The reliable HPE ProLiant DL20 Gen10 Plus server delivers a compact and versatile server with enhanced security at an affordable price. Deploy the portable short-depth rack form factor in small, remote, or branch offices.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE DL20 in Mahrashtra, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for HPE DL20.</p>
          
        </>

      ),
      enterpriseFeature: (
        <>
          <li>
            Supports Intel® Xeon® E-2300 series processors, up to 8 cores.
          </li>
          <li>
            290W non-hot-plug power supply with Platinum power efficiency.
          </li>
        </>
      ),
      downloadLink: DL20,
      hyperlinks: [],
    },

    //DL360
    {
      images: [
        require("../../../assets/productImages/DL360-1.png"),
        require("../../../assets/productImages/DL360-2.png"),
        require("../../../assets/productImages/DL360-3.png"),
        require("../../../assets/productImages/DL360-4.png"),
      ],
      title: "HPE DL360",
      about:(
        <>
        <p>        "The HPE  DL360 Gen10 server delivers security, agility and flexibility without compromise. It supports the Intel® Xeon® Scalable processor.",
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE DL360 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for HPE DL360.</p>
        
        </>

      ),
      enterpriseFeature: (
        <>
          <li>
            Support for additional second generation Intel® Xeon® Scalable
            processors with improved price/performance.
          </li>
          <li>Innovative Design for Flexibility and Choice.</li>
        </>
      ),
      downloadLink: DL360,
      hyperlinks: [],
    },

    //DL380
    {
      images: [
        require("../../../assets/productImages/DL380-1.png"),
        require("../../../assets/productImages/DL380-2.png"),
        require("../../../assets/productImages/DL380-3.png"),
        require("../../../assets/productImages/DL380-4.png"),
      ],
      title: "HPE DL380",
      about:(
        <>
        <p>The HPE ProLiant DL380 Gen10 Plus server is adaptable for diverse workloads and environments, providing you with the right balance of expandability and scalability.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE DL380 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for HPE DL380.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Supports 3rd Generation Intel® Xeon® Scalable Processors.</li>
          <li>Support for industry-standard OCP 3.0 Network Adapters.</li>
          <li>
            Support for Trimodal (NVMe/SAS/SATA) HPE Smart Array controllers.
          </li>
        </>
      ),
      downloadLink: DL380,
      hyperlinks: [],
    },

    //DL365
    {
      images: [
        require("../../../assets/productImages/DL365-1.png"),
        require("../../../assets/productImages/DL365-2.png"),
        require("../../../assets/productImages/DL365-3.png"),
        require("../../../assets/productImages/DL365-4.png"),
      ],
      title: "HPE DL365",
      about:(
        <>
        <p>Equipped with PCIe Gen4 capabilities, the HPE ProLiant DL365 Gen10 Plus server offers improved data transfer rates and higher networking speeds.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE DL365 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount  for HPE DL365.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Intelligent Automation.</li>
          <li>360 Degree Security.</li>
          <li>Workload Optimization.</li>
        </>
      ),
      downloadLink: DL365,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //NAS 1660
    {
      images: [
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
        require("../../../assets/productImages/NAS-storeeasy-1660.png"),
      ],
      title: "HPE NAS 1660",
      about:(
        <>
        <p>If you need an easy-to-manage centralized space for securely storing documents, images, audio, and video files, look no further than HPE StoreEasy 1000 Storage with the HPE StoreEasy Management Console.
        </p>
        <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE NAS 1660 in  Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount  for HPE NAS 1660.</p>
        

        </>
      ),
      enterpriseFeature: (
        <>
          <li> Intel Xeon processors, ensuring robust performance for a variety of workloads.</li>
          <li>Offers various RAID configurations (RAID 0, 1, 5, 6, 10, etc.) to ensure data redundancy and protection against disk failures.</li>
          <li>The NAS supports a range of storage configurations, with support for both hard drives (HDD) and solid-state drives (SSD). It offers a range of storage capacities that can be scaled to meet growing data demands.</li>
        </>
      ),
      downloadLink: NAS1660,
      hyperlinks: [],
    },

    //HPE MSA 2060
    {
      images: [
        require("../../../assets/productImages/HPE-MSA-2060.png"),
        require("../../../assets/productImages/HPE-MSA-2060.png"),
        require("../../../assets/productImages/HPE-MSA-2060.png"),
        require("../../../assets/productImages/HPE-MSA-2060.png"),
      ],
      title: "HPE MSA 2060",
      about:(
        <>
        <p>The HPE MSA 2060 Storage is a flash-ready hybrid storage system designed to deliver hands-free, affordable application acceleration for small and remote office deployments.
        </p>
       <br></br>
        <p>
        Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of HPE MSA 2060 in  Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Chennai. Contact us to avail best prices and discount  for HPE MSA 2060.</p>
        
        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Leverage Data Protection Features to Keep Your Business Running in
            the Event of a Disaster.
          </li>
          <li> Accelerate Applications Affordably.</li>
        </>
      ),
      downloadLink: HPEMSA2060,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="hp-enterprise-page">
      <Helmet>
        <title>HPE Server - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealer Tags */}
        <meta name="keywords" content="HPE Server Dealer in Pune" />
        <meta name="keywords" content="Best HPE Server Dealer in Pune" />
        <meta name="keywords" content="HPE Server Distributor in Pune" />

        <meta name="keywords" content="HPE Server Dealer in Mumbai" />
        <meta name="keywords" content="Best HPE Server Dealer in Mumbai" />
        <meta name="keywords" content="HPE Server Distributor in Mumbai" />

        <meta name="keywords" content="HPE Server Dealer in Maharashtra" />
        <meta name="keywords" content="Best HPE Server Dealer in Maharashtra" />
        <meta name="keywords" content="HPE Server Distributor in Maharashtra" />

        <meta name="keywords" content="HPE Server Dealer in Vadodara" />
        <meta name="keywords" content="Best HPE Server Dealer in Vadodara" />
        <meta name="keywords" content="HPE Server Distributor in Vadodara" />

        <meta name="keywords" content="HPE Server Dealer in Surat" />
        <meta name="keywords" content="Best HPE Server Dealer in Surat" />
        <meta name="keywords" content="HPE Server Distributor in Surat" />

        <meta name="keywords" content="HPE Server Dealer in Ahmedabad" />
        <meta name="keywords" content="Best HPE Server Dealer in Ahmedabad" />
        <meta name="keywords" content="HPE Server Distributor in Ahmedabad" />

        <meta name="keywords" content="HPE Server Dealer in Gujarat" />
        <meta name="keywords" content="Best HPE Server Dealer in Gujarat" />
        <meta name="keywords" content="HPE Server Distributor in Gujarat" />

        <meta name="keywords" content="HPE Server Dealer in Indore" />
        <meta name="keywords" content="Best HPE Server Dealer in Indore" />
        <meta name="keywords" content="HPE Server Distributor in Indore" />

        <meta name="keywords" content="HPE Server Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best HPE Server Dealer in Madhya Pradesh" />
        <meta name="keywords" content="HPE Server Distributor in Madhya Pradesh" />

        <meta name="keywords" content="HPE Server Dealer in Jaipur" />
        <meta name="keywords" content="Best HPE Server Dealer in Jaipur" />
        <meta name="keywords" content="HPE Server Distributor in Jaipur" />

        <meta name="keywords" content="HPE Server Dealer in Rajasthan" />
        <meta name="keywords" content="Best HPE Server Dealer in Rajasthan" />
        <meta name="keywords" content="HPE Server Distributor in Rajasthan" />

        <meta name="keywords" content="HPE Server Dealer in Noida" />
        <meta name="keywords" content="Best HPE Server Dealer in Noida" />
        <meta name="keywords" content="HPE Server Distributor in Noida" />

        <meta name="keywords" content="HPE Server Dealer in Gurugram" />
        <meta name="keywords" content="Best HPE Server Dealer in Gurugram" />
        <meta name="keywords" content="HPE Server Distributor in Gurugram" />

        <meta name="keywords" content="HPE Server Dealer in Delhi" />
        <meta name="keywords" content="Best HPE Server Dealer in Delhi" />
        <meta name="keywords" content="HPE Server Distributor in Delhi" />

        <meta name="keywords" content="HPE Server Dealer in Kolkata" />
        <meta name="keywords" content="Best HPE Server Dealer in Kolkata" />
        <meta name="keywords" content="HPE Server Distributor in Kolkata" />

        <meta name="keywords" content="HPE Server Dealer in West Bengal" />
        <meta name="keywords" content="Best HPE Server Dealer in West Bengal" />
        <meta name="keywords" content="HPE Server Distributor in West Bengal" />

        <meta name="keywords" content="HPE Server Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best HPE Server Dealer in Bhubaneswar" />
        <meta name="keywords" content="HPE Server Distributor in Bhubaneswar" />

        <meta name="keywords" content="HPE Server Dealer in Odisha" />
        <meta name="keywords" content="Best HPE Server Dealer in Odisha" />
        <meta name="keywords" content="HPE Server Distributor in Odisha" />

        <meta name="keywords" content="HPE Server Dealer in Hyderabad" />
        <meta name="keywords" content="Best HPE Server Dealer in Hyderabad" />
        <meta name="keywords" content="HPE Server Distributor in Hyderabad" />

        <meta name="keywords" content="HPE Server Dealer in Telangana" />
        <meta name="keywords" content="Best HPE Server Dealer in Telangana" />
        <meta name="keywords" content="HPE Server Distributor in Telangana" />

        <meta name="keywords" content="HPE Server Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best HPE Server Dealer in Visakhapatnam" />
        <meta name="keywords" content="HPE Server Distributor in Visakhapatnam" />

        <meta name="keywords" content="HPE Server Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best HPE Server Dealer in Andhra Pradesh" />
        <meta name="keywords" content="HPE Server Distributor in Andhra Pradesh" />

        <meta name="keywords" content="HPE Server Dealer in Chennai" />
        <meta name="keywords" content="Best HPE Server Dealer in Chennai" />
        <meta name="keywords" content="HPE Server Distributor in Chennai" />

        <meta name="keywords" content="HPE Server Dealer in Coimbatore" />
        <meta name="keywords" content="Best HPE Server Dealer in Coimbatore" />
        <meta name="keywords" content="HPE Server Distributor in Coimbatore" />

        <meta name="keywords" content="HPE Server Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best HPE Server Dealer in Tamil Nadu" />
        <meta name="keywords" content="HPE Server Distributor in Tamil Nadu" />

        <meta name="keywords" content="HPE Server Dealer in Bengaluru" />
        <meta name="keywords" content="Best HPE Server Dealer in Bengaluru" />
        <meta name="keywords" content="HPE Server Distributor in Bengaluru" />

        <meta name="keywords" content="HPE Server Dealer in Karnataka" />
        <meta name="keywords" content="Best HPE Server Dealer in Karnataka" />
        <meta name="keywords" content="HPE Server Distributor in Karnataka" />


        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.calidtech.com/EnterpriseHPSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Server and Storage Solution - HPE
      </div>
      <div className="hp-enterprise-subproduct-nav">
        <button
          className={`hp-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-hp-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Tower and Rackmount Servers
        </button>
        <button
          className={`hp-enterprise-subproduct-nav-button ${currentSubproduct === 2 ? "active-hp-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Storage
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="hp-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="hp-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-hp-enterprise" : ""
                  }
                >
                  <button
                    className="hp-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseHPSubcategoryPage;
