import React from "react";
import "./ProductMain.css";
import FactoryDigitalTransformationCalidTech from "../../assets/pdf/FactoryDigitalTransformation_CalidTech.pdf";

function ProductmainMenu() {
  return (
    <header className="manu-header">
      <div className="service-header-text">
      <h1 style={{ fontSize: '44px', lineHeight: '1', fontWeight: 'normal',width: '100%'  }}>
  Comprehensive Industrial Solutions
  <br />
  <span id="service-photography"></span>
</h1>

        <p>
        Calid Technologies provide a wide range of industrial products, including Industrial Edge Connectivity, Communication, Computing, Analytics, and OT Security solutions, across India and in major cities like Mumbai, Delhi, Bengaluru, Chennai, Kolkata, Hyderabad, Pune, Ahmedabad, and Jaipur. Our solutions are designed to enhance operational efficiency and cybersecurity, meeting the needs of industries nationwide.
        </p>
        <a
          href={FactoryDigitalTransformationCalidTech}
          download="FactoryDigitalTransformation_CalidTech.pdf"
          className="prodbut center Downloadpdf"
        >
          Get PDF
        </a>
      </div>
    </header>
  );
}

function Productmainmanu() {
  return (
    <div className="ServiceApp">
      <ProductmainMenu />
    </div>
  );
}

export default Productmainmanu;
