import React from "react";

const ExpertsSection = () => {
  return (
    <div style={styles.section}>
      <h2 style={styles.heading}>Meet Our Experts</h2>
      <p style={styles.subheading}>
        A team of professionals with expertise in diverse domains, here to guide and assist you.
      </p>
      <div style={styles.expertsContainer}>
        {/* Expert 1 */}
        <div style={styles.expertCard}>
          <h3 style={styles.expertName}>Abhishek Trivedi</h3>
          <p style={styles.expertRole}>Sales Expert</p>
          <a
            href="https://www.linkedin.com/in/abhishek-trivedi-47528528?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.linkedinLink}
          >
            LinkedIn
          </a>
        </div>
        {/* Expert 2 */}
        <div style={styles.expertCard}>
          <h3 style={styles.expertName}>Abhijeet Ghosh</h3>
          <p style={styles.expertRole}>Wireless & Switching Expert</p>
          <a
            href="https://www.linkedin.com/in/abhijeetghosh?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.linkedinLink}
          >
            LinkedIn
          </a>
        </div>
        {/* Expert 3 */}
        <div style={styles.expertCard}>
          <h3 style={styles.expertName}>Debankur Basu</h3>
          <p style={styles.expertRole}>IoT Expert</p>
          <a
            href="https://www.linkedin.com/in/debankur-basu-1622423?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.linkedinLink}
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    width: "100%",
    padding: "50px 20px",
    backgroundColor: "#f3f4f6",
    textAlign: "center",
  },
  heading: {
    fontSize: "32px",
    marginBottom: "15px",
    color: "#222",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
  },
  subheading: {
    fontSize: "18px",
    marginBottom: "40px",
    color: "#555",
    fontFamily: "'Roboto', sans-serif",
  },
  expertsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    flexWrap: "wrap",
  },
  expertCard: {
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "25px",
    width: "280px",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
    textAlign: "center",
    transition: "transform 0.3s, box-shadow 0.3s",
  },
  expertCardHover: {
    transform: "scale(1.05)",
    boxShadow: "0 12px 30px rgba(0, 0, 0, 0.2)",
  },
  expertName: {
    fontSize: "22px",
    fontWeight: "700",
    margin: "10px 0",
    color: "#333",
  },
  expertRole: {
    fontSize: "16px",
    color: "#777",
    marginBottom: "20px",
  },
  linkedinLink: {
    fontSize: "16px",
    color: "#0077b5",
    textDecoration: "none",
    fontWeight: "600",
    transition: "color 0.3s",
  },
  linkedinLinkHover: {
    color: "#005582",
  },
};

export default ExpertsSection;
