// ManufacturingTeltonikaSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingTeltonikaSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import RUT300 from "../../../assets/pdf/RUT-300.pdf";
import RUT956 from "../../../assets/pdf/RUT-956.pdf";
import RUTX50 from "../../../assets/pdf/RUTX50.pdf";
import TRB245 from "../../../assets/pdf/TRB245.pdf";
import TRB255 from "../../../assets/pdf/TRB255.pdf";
import TSW202 from "../../../assets/pdf/TSW202.pdf";
import TSW212 from "../../../assets/pdf/TSW212.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";


const ManufacturingTeltonikaSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Teltonika",
  // };

  const productDataList1 = [
    // RUT 300
    {
      images: [
        require("../../../assets/productImages/RUT-300-1.jpg"),
        require("../../../assets/productImages/RUT-300-2.jpg"),
        require("../../../assets/productImages/RUT-300-3.jpg"),
        require("../../../assets/productImages/RUT-300-4.jpg"),
      ],
      title: "RUT 300",
      about: (
        <>
          <p>
            Captive portal (Hotspot), internal/external Radius server, SMS
            authorization, internal/external landing page, 9 default
            customizable themes and option to upload and download customised
            hotspot.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of RUT 300 in Maharashtra, Bhavnagar, MP,
            Bhiwadi, Gurugram, Uttarakhand, Patratu, Orissa, Bengaluru. Contact
            us to avail best prices and discount for RUT 300.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>SSTP client instance support</li>
          <li>ZeroTier VPN client support</li>
        </>
      ),
      downloadLink: RUT300,
      hyperlinks: [],
    },

    //RUT 956
    {
      images: [
        require("../../../assets/productImages/RUT-956-1.jpg"),
        require("../../../assets/productImages/RUT-956-2.jpg"),
        require("../../../assets/productImages/RUT-956-3.jpg"),
        require("../../../assets/productImages/RUT-956-4.jpg"),
      ],
      title: "RUT 956",
      about: (
        <>
          <p>
            RUT956 is an industrial router that combines cellular, Wi-Fi, and
            wired connectivity options with automatic WAN failover and GNSS
            capabilities.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of RUT 956 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore, Vapi, Halol, Nimrana. Contact
            us to avail best prices and discount for RUT 956.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Band lock, Used band status display</li>
          <li>Balance Internet traffic over multiple WAN connections</li>
          <li>Possibility to mount remote file system via SSH protocol</li>
        </>
      ),
      downloadLink: RUT956,
      hyperlinks: [],
    },

    //RUTX50
    {
      images: [
        require("../../../assets/productImages/RUTX50-1.jpg"),
        require("../../../assets/productImages/RUTX50-2.jpg"),
        require("../../../assets/productImages/RUTX50-3.jpg"),
        require("../../../assets/productImages/RUTX50-4.jpg"),
      ],
      title: "RUTX50",
      about: (
        <>
          <p>
            RUTX50 is a dual-SIM multi-network router offering 5G mobile
            communication for high-speed and data-heavy applications. Together
            with 5x Gigabit Ethernet ports, it provides data connection
            redundancy.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of RUTX50 inPune, Aurangabad, Nasik, Ahmednagar, Taloja, Shedung, Rabale, Kolhapur,Vapi, Halol, Vadodara, Bhavnagar, Surat,Bhilwara, Solan,Sambhalpur, Hosur, Belgaum. Contact
            us to avail best prices and discount for RUTX50.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>
            Router assigns its mobile WAN IP address to another device on LAN
          </li>
          <li>
            Direct connection (bridge) between mobile ISP and device on LAN
          </li>
        </>
      ),
      downloadLink: RUTX50,
      hyperlinks: [],
    },

    //TRB245
    {
      images: [
        require("../../../assets/productImages/TRB245-1.jpg"),
        require("../../../assets/productImages/TRB245-2.jpg"),
        require("../../../assets/productImages/TRB245-3.jpg"),
        require("../../../assets/productImages/TRB245-4.jpg"),
      ],
      title: "TRB245",
      about: (
        <>
          <p>
            Captive portal, internal/external Radius server, SMS authorization,
            internal/external landing page, 9 default customizable themes and
            option to upload and download customised hotspot themes.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of TRB245 in Maharashtra, Bhavnagar, MP,
            Bhiwadi, Gurugram, Uttarakhand, Patratu, Orissa, Bengaluru. Contact
            us to avail best prices and discount for TRB245.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>SSTP client instance support</li>
          <li>ZeroTier VPN client support</li>
        </>
      ),
      downloadLink: TRB245,
      hyperlinks: [],
    },

    //TRB255:
    {
      images: [
        require("../../../assets/productImages/TRB255-1.jpg"),
        require("../../../assets/productImages/TRB255-2.jpg"),
        require("../../../assets/productImages/TRB255-3.jpg"),
        require("../../../assets/productImages/TRB255-4.jpg"),
      ],
      title: "TRB255",
      about: (
        <>
          <p>
            Visual representation of your network, showing which devices are
            connected to which other devices. Static and dynamic IP allocation,
            DHCP Relay. View device ports, enable and disable each of them.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of TRB255 in Ahmednagar, Taloja, Shedung, Rabale, Kolhapur, Bhavnagar, Surat, Ahmedabad, Rajkot, Anand, patratu, Jamshedpur, Bengaluru, Rourkela, Sambhalpur, Bhubeneshwar, Cuttak, Mumbai. Contact
            us to avail best prices and discount for TRB255.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Method of building scalable IPsec VPNs</li>
          <li>SSTP client instance support</li>
        </>
      ),
      downloadLink: TRB255,
      hyperlinks: [],
    },

    //TSW202:
    {
      images: [
        require("../../../assets/productImages/TSW202-1.jpg"),
        require("../../../assets/productImages/TSW202-2.jpg"),
        require("../../../assets/productImages/TSW202-3.jpg"),
        require("../../../assets/productImages/TSW202-4.jpg"),
      ],
      title: "TSW202",
      about: (
        <>
          <p>
            TSW202 is the first-ever Teltonika Networks managed PoE+ Ethernet
            switch, designed to provide granular network management capabilities
            while centralising power distribution.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of TSW202 in Maharashtra, Bhavnagar, MP,
            Bhiwadi, Gurugram, Uttarakhand, Patratu, Orissa, Bengaluru. Contact
            us to avail best prices and discount for TSW202.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>MRP client role, MRP manager role</li>
          <li>Capability to restart PoE in a specific port</li>
        </>
      ),
      downloadLink: TSW202,
      hyperlinks: [],
    },

    //TSW212:
    {
      images: [
        require("../../../assets/productImages/TSW212-1.jpg"),
        require("../../../assets/productImages/TSW212-2.jpg"),
        require("../../../assets/productImages/TSW212-3.jpg"),
        require("../../../assets/productImages/TSW212-4.jpg"),
      ],
      title: "TSW212",
      about: (
        <>
          <p>
            Visual representation of your network, showing which devices are
            connected to which other devices. Static and dynamic IP allocation,
            DHCP Relay. View device ports, enable and disable each of them.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of TSW212 in Maharashtra, Bhavnagar, MP,
            Bhiwadi, Gurugram, Uttarakhand, Patratu, Orissa, Bengaluru. Contact
            us to avail best prices and discount for TSW212.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>
            STinc offers encryption, authentication and compression in it's
            tunnels.
          </li>
          <li>Method of building scalable IPsec VPNs</li>
        </>
      ),
      downloadLink: TSW212,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="teltonika-page">
      <Helmet>
        <title>Teltonika - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta
          name="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Teltonika Dealer in Pune" />
        <meta name="keywords" content="Best Teltonika Dealer in Pune" />
        <meta name="keywords" content="Teltonika Distributor in Pune" />

        <meta name="keywords" content="Teltonika Dealer in Mumbai" />
        <meta name="keywords" content="Best Teltonika Dealer in Mumbai" />
        <meta name="keywords" content="Teltonika Distributor in Mumbai" />

        <meta name="keywords" content="Teltonika Dealer in Maharashtra" />
        <meta name="keywords" content="Best Teltonika Dealer in Maharashtra" />
        <meta name="keywords" content="Teltonika Distributor in Maharashtra" />

        <meta name="keywords" content="Teltonika Dealer in Vadodara" />
        <meta name="keywords" content="Best Teltonika Dealer in Vadodara" />
        <meta name="keywords" content="Teltonika Distributor in Vadodara" />

        <meta name="keywords" content="Teltonika Dealer in Surat" />
        <meta name="keywords" content="Best Teltonika Dealer in Surat" />
        <meta name="keywords" content="Teltonika Distributor in Surat" />

        <meta name="keywords" content="Teltonika Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Teltonika Dealer in Ahmedabad" />
        <meta name="keywords" content="Teltonika Distributor in Ahmedabad" />

        <meta name="keywords" content="Teltonika Dealer in Indore" />
        <meta name="keywords" content="Best Teltonika Dealer in Indore" />
        <meta name="keywords" content="Teltonika Distributor in Indore" />

        <meta name="keywords" content="Teltonika Dealer in Jaipur" />
        <meta name="keywords" content="Best Teltonika Dealer in Jaipur" />
        <meta name="keywords" content="Teltonika Distributor in Jaipur" />

        <meta name="keywords" content="Teltonika Dealer in Noida" />
        <meta name="keywords" content="Best Teltonika Dealer in Noida" />
        <meta name="keywords" content="Teltonika Distributor in Noida" />

        <meta name="keywords" content="Teltonika Dealer in Gurugram" />
        <meta name="keywords" content="Best Teltonika Dealer in Gurugram" />
        <meta name="keywords" content="Teltonika Distributor in Gurugram" />

        <meta name="keywords" content="Teltonika Dealer in Delhi" />
        <meta name="keywords" content="Best Teltonika Dealer in Delhi" />
        <meta name="keywords" content="Teltonika Distributor in Delhi" />

        <meta name="keywords" content="Teltonika Dealer in Kolkata" />
        <meta name="keywords" content="Best Teltonika Dealer in Kolkata" />
        <meta name="keywords" content="Teltonika Distributor in Kolkata" />

        <meta name="keywords" content="Teltonika Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Teltonika Dealer in Bhubaneswar" />
        <meta name="keywords" content="Teltonika Distributor in Bhubaneswar" />

        <meta name="keywords" content="Teltonika Dealer in Hyderabad" />
        <meta name="keywords" content="Best Teltonika Dealer in Hyderabad" />
        <meta name="keywords" content="Teltonika Distributor in Hyderabad" />

        <meta name="keywords" content="Teltonika Dealer in Visakhapatnam" />
        <meta
          name="keywords"
          content="Best Teltonika Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Teltonika Distributor in Visakhapatnam"
        />

        <meta name="keywords" content="Teltonika Dealer in Chennai" />
        <meta name="keywords" content="Best Teltonika Dealer in Chennai" />
        <meta name="keywords" content="Teltonika Distributor in Chennai" />

        <meta name="keywords" content="Teltonika Dealer in Coimbatore" />
        <meta name="keywords" content="Best Teltonika Dealer in Coimbatore" />
        <meta name="keywords" content="Teltonika Distributor in Coimbatore" />

        <meta name="keywords" content="Teltonika Dealer in Bengaluru" />
        <meta name="keywords" content="Best Teltonika Dealer in Bengaluru" />
        <meta name="keywords" content="Teltonika Distributor in Bengaluru" />

        <meta name="keywords" content="Teltonika Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Teltonika Dealer in Tamil Nadu" />
        <meta name="keywords" content="Teltonika Distributor in Tamil Nadu" />

        <meta name="keywords" content="Teltonika Dealer in Karnataka" />
        <meta name="keywords" content="Best Teltonika Dealer in Karnataka" />
        <meta name="keywords" content="Teltonika Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta
          name="twitter:description"
          content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://calidtech.com/ManufacturingTeltonikaSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Analytics - Teltonika
      </div>
      <div className="teltonika-subproduct-nav">
        <button
          className={`teltonika-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-teltonika-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Teltonika
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="teltonika-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-teltonika-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-teltonika" : ""}
                >
                  <button
                    className="teltonika-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingTeltonikaSubcategoryPage;
