// EnterpriseServerAndStorageSolutionCategoryPage.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EnterpriseServerAndStorageSolutionCategoryPage.css";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import heroprod from "../../../assets/images/server1.jpg";
import heroprod1 from "../../../assets/images/server2.jpg";
import routersImage from "../../../assets/images/hpe.png";
import switchesImage from "../../../assets/images/dell.png";
import accessPointsImage from "../../../assets/images/og_lenovo-_logo.png";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseSubCategory from "../../EntertrpriseComponents/EnterpriseSubCategory";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";



const EnterpriseServerAndStorageSolutionCategoryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subcategories = [
    {
      name: "HPE",
      image: routersImage,
      path: "/EnterpriseHPSubcategoryPage",
      description: "",
    },
    {
      name: "Dell",
      image: switchesImage,
      path: "/EnterpriseDellSubcategoryPage",
      description: "",
    },
    {
      name: "Lenovo",
      image: accessPointsImage,
      path: "/EnterpriseLenovoSubcategoryPage",
      description: "",
    },
  ];

  return (
    <div className="enterprise-category">
      <Helmet>
        <title>Server and Storage Solution -  HPE Server, Dell Server, Dell Storage, Lenovo Servere, FortiGate Firewall, Sophos Firewall, Captive Portal Server Products - Dealer, Distributor and supplier in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Discover Calid Technologies Enterprise Server and Storage solutions featuring reliable products from HPE, Dell, and Lenovo. Calid Technologies provide scalable and efficient server and storage systems tailored to meet your business's growing needs." />
        <meta name="keywords" content="Enterprise-Grade Servers,IT Infrastructure Solutions,Data Storage Solutions,Business Storage Systems,Scalable Server Solutions,Lenovo Enterprise Products,Dell Storage Systems,Dell Storage Systems,HPE Servers,Storage Solutions,Enterprise Server Solutions,Server and Storage Solution supplier in mumbai" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Server and Storage Solution" />
        <meta property="og:description" content="Discover Calid Technologies Enterprise Server and Storage solutions featuring reliable products from HPE, Dell, and Lenovo. Calid Technologies provide scalable and efficient server and storage systems tailored to meet your business's growing needs." />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://calidtech.com/" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Server and Storage Solution" />
        <meta name="twitter:description" content="Enterprise-Grade Servers,IT Infrastructure Solutions,Data Storage Solutions,Business Storage Systems,Scalable Server Solutions,Lenovo Enterprise Products,Dell Storage Systems,Dell Storage Systems,HPE Servers,Storage Solutions,Enterprise Server Solutions,Server and Storage Solution supplier in mumbai" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Server and Storage Solution" />
        <meta itemprop="description" content="Discover Calid Technologies Enterprise Server and Storage solutions featuring reliable products from HPE, Dell, and Lenovo. Calid Technologies provide scalable and efficient server and storage systems tailored to meet your business's growing needs." />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Discover Calid Technologies Enterprise Server and Storage solutions featuring reliable products from HPE, Dell, and Lenovo. Calid Technologies provide scalable and efficient server and storage systems tailored to meet your business's growing needs." />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseServerAndStorageSolutionCategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Server and Storage Solutions
      </div>
      <div className="container-enterprise mt-5">
        <Slider {...settings}>
          <div>
            <img
              src={heroprod}
              alt="Slide 1"
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div>
            <img
              src={heroprod1}
              alt="Slide 2"
              style={{ width: "100%", display: "block" }}
            />
          </div>
        </Slider>
        <br></br>
        <br></br>

        <h1 className="enterprise-category-heading">
          <div class="main-heading-entire-site">
            Server and Storage Solutions
          </div>
        </h1>
        <p className="enterprise-category-information">
        At Calid Technologies, we recognize the critical role that reliable server and storage solutions play in driving your business success. As an authorized dealer and supplier across India, including major cities such as Mumbai, Pune, Delhi, Bengaluru, Hyderabad, and Chennai, we offer an extensive range of products from industry-leading brands like HP, Dell, and Lenovo. Whether you're a small business or a large enterprise, our solutions are tailored to meet your specific needs, providing exceptional performance, reliability, and scalability to support your growth and operational demands.







        </p>
        <h2 className="enterprise-subcategory-heading">
          <div class="main-heading-entire-site">Subcategories</div>
        </h2>
        <EnterpriseSubCategory subcategories={subcategories} />
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseServerAndStorageSolutionCategoryPage;
