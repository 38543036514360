import React, { useState, useRef } from "react";
import "./EnterpriseCaseStudy.css";
import "../../../Common Components/Hero.css";
import KarkinosHealthcareCaseStudy from "../../../assets/pdf/KarkinosHealthcareCaseStudy.pdf";
import CaseStudyIndividualPage from "../../../assets/images/CaseStudyIndividualPage.jpg";
import ProductsLinked from "../../../Common Components/ProductsLinked";

const Karkinos = () => {
  const products = [
    {
      id: 1,
      name: "Ruckus R650",
      image: "path/to/image1.jpg",
      link: "http://example.com/product1",
    },
    {
      id: 2,
      name: "D-Link 1510-28X",
      image: "path/to/image2.jpg",
      link: "http://example.com/product2",
    },
    {
      id: 3,
      name: "Cisco CBS350-24T",
      image: "path/to/image3.jpg",
      link: "http://example.com/product3",
    },
    {
      id: 4,
      name: "Cisco CBS350-24P",
      image: "path/to/image2.jpg",
      link: "http://example.com/product2",
    },
    {
      id: 5,
      name: "Cisco CBS350-24XS",
      image: "path/to/image3.jpg",
      link: "http://example.com/product3",
    },
    // Add more products as needed
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const emailInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userEmail = emailInputRef.current.value;
    const formId = "1FAIpQLScCh_Wn_mi8GlNCFJg0xw82iFGq9m3a7Z2STUBKRA2sSh9TKQ";

    // Custom messages for fields in Google Form
    const entryEmail = "entry.1870394174";  // email field ID
    const entryName = "entry.1812720550";   // name field ID
    const entryPhone = "entry.97349512";    // phone field ID
    const entryMessage = "entry.231532781"; // message field ID

    // Construct the form submission URL
    const formUrl = `https://docs.google.com/forms/d/e/${formId}/formResponse?${entryName}=Karkinos%20case%20study%20downloaded&${entryEmail}=${encodeURIComponent(
      userEmail
    )}&${entryPhone}=Karkinos%20case%20study%20downloaded&${entryMessage}=Karkinos%20case%20study%20downloaded`;

    try {
      // Submit the form data using Fetch API
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
      });

      // Show the download button upon successful submission
      setShowPopup(false);
      setShowDownloadPopup(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowDownloadPopup(false);
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = KarkinosHealthcareCaseStudy;
    link.setAttribute("download", "Karkinos Healthcare Case Study.pdf");
    link.click();
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
    setShowDownloadPopup(false);
  };

  return (
    <div className="Individual-CaseStudy-Div">
      <div
        className="ImageBackgroundWrap Background-fixed"
        style={{
          backgroundImage: `url(${CaseStudyIndividualPage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="ImageInnerWrap"></div>
      </div>

      <div className="headline" id="CaseStudyDv">
        <h1>KARKINOS HEALTHCARE PVT LTD</h1>
      </div>
      <div className="case-study-div">
        <div className="case-study">
          <div className="case-study-table">
            <table>
              <tbody>
                <tr>
                  <td className="table-label">Assignment name:</td>
                  <td>
                    Organisation wide consulting and setting up of the ICT
                    infrastructure.
                  </td>
                </tr>
                <tr>
                  <td className="table-label">
                    Approx. value of the contract:
                  </td>
                  <td>INR 80,00,000</td>
                </tr>
                <tr>
                  <td className="table-label">Country:</td>
                  <td>India</td>
                </tr>
                <tr>
                  <td className="table-label">Location within country:</td>
                  <td>HQ in Mumbai and PAN India Regional offices</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Duration of assignment (months):
                  </td>
                  <td>Every office project completion took 2 months.</td>
                </tr>
                <tr>
                  <td className="table-label">Name of client:</td>
                  <td>Karkinos Heathcare</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Total No of staff-months of the assignment:
                  </td>
                  <td>2</td>
                </tr>
                <tr>
                  <td className="table-label">
                    Name of senior professional staff of your firm involved and
                    functions performed:
                  </td>
                  <td>
                    Mr. Debankur Basu – Senior Technical Consultant Mr. Abhijeet
                    Ghosh – Lead Technical Consultant
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button className="read-more-btn" onClick={handleOpenPopup}>
            Read More
          </button>
          {showPopup && (
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
              <p className="popup-message">
                Want to read more of this case study? Please give us your email:
              </p>
              <form onSubmit={handleSubmit} className="email-form">
                <input
                  type="email"
                  ref={emailInputRef}
                  name="email"
                  placeholder="Enter your email"
                  required
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
          {showDownloadPopup && (
            <div className="popup">
              <button className="close-btn" onClick={handleClosePopup}>
                Close
              </button>
              <p>
                Thank you for your email. Your case study PDF is ready for
                download:
              </p>
              <div className="download-button-container">
                <button className="download-btn" onClick={handleDownloadPDF}>
                  Download PDF
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ProductsLinked products={products} />
    </div>
  );
};

export default Karkinos;
