// ManufacturingDalnexSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingDalnexSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import Dalnex from "../../../assets/pdf/Dalnex.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";


const ManufacturingDalnexSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Dalnex",
  // };

  const productDataList1 = [
    // Dalnex
    {
      images: [
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
      ],
      title: "Dalnex",
      about: (
        <>
          <p>
            The  Dalnex IOT gateway is able collect data from sensors/energy meters/water
            sensors and any field device which run on Modbus RTU (RS232/422/485)
            interface and transmit it to onprem/ cloud server.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Dalnex IOT gateway  in Nashik, Gujarat, Mandideep,
            Bangalore, Nimrana, Manglore, Maharashtra. Contact us to avail best
            prices and discount for Dalnex IOT gateway.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Predictive Maintenance.</li>
          <li>Productivity Analysis.</li>
        </>
      ),
      downloadLink: Dalnex,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="dalnex-page">
      <Helmet>
        <title>Dalnex - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Discover Dalnex analytics solutions at Calid Technologies. Leverage advanced data analytics tools to optimize your manufacturing processes and gain actionable insights. Serving Mumbai, Pune, and across India." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation,Industrial data analytics,India analytics solutions,Pune manufacturing analytics,Mumbai data analytics,Calid Technologies Dalnex,Dalnex analytics products,Data insights tools,Manufacturing analytics solutions,Dalnex data analytics,Analytics Dalnex,Dalnex product supplier in Mumbai pune abnd across the India" />
        <meta
          name="description"
          content="Discover Dalnex analytics solutions at Calid Technologies. Leverage advanced data analytics tools to optimize your manufacturing processes and gain actionable insights. Serving Mumbai, Pune, and across India."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation,Industrial data analytics,India analytics solutions,Pune manufacturing analytics,Mumbai data analytics,Calid Technologies Dalnex,Dalnex analytics products,Data insights tools,Manufacturing analytics solutions,Dalnex data analytics,Analytics Dalnex,Dalnex product supplier in Mumbai pune abnd across the India"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Dalnex Dealer in Pune" />
        <meta name="keywords" content="Best Dalnex Dealer in Pune" />
        <meta name="keywords" content="Dalnex Distributor in Pune" />

        <meta name="keywords" content="Dalnex Dealer in Mumbai" />
        <meta name="keywords" content="Best Dalnex Dealer in Mumbai" />
        <meta name="keywords" content="Dalnex Distributor in Mumbai" />

        <meta name="keywords" content="Dalnex Dealer in Maharashtra" />
        <meta name="keywords" content="Best Dalnex Dealer in Maharashtra" />
        <meta name="keywords" content="Dalnex Distributor in Maharashtra" />

        <meta name="keywords" content="Dalnex Dealer in Vadodara" />
        <meta name="keywords" content="Best Dalnex Dealer in Vadodara" />
        <meta name="keywords" content="Dalnex Distributor in Vadodara" />

        <meta name="keywords" content="Dalnex Dealer in Surat" />
        <meta name="keywords" content="Best Dalnex Dealer in Surat" />
        <meta name="keywords" content="Dalnex Distributor in Surat" />

        <meta name="keywords" content="Dalnex Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Dalnex Dealer in Ahmedabad" />
        <meta name="keywords" content="Dalnex Distributor in Ahmedabad" />

        <meta name="keywords" content="Dalnex Dealer in Indore" />
        <meta name="keywords" content="Best Dalnex Dealer in Indore" />
        <meta name="keywords" content="Dalnex Distributor in Indore" />

        <meta name="keywords" content="Dalnex Dealer in Jaipur" />
        <meta name="keywords" content="Best Dalnex Dealer in Jaipur" />
        <meta name="keywords" content="Dalnex Distributor in Jaipur" />

        <meta name="keywords" content="Dalnex Dealer in Noida" />
        <meta name="keywords" content="Best Dalnex Dealer in Noida" />
        <meta name="keywords" content="Dalnex Distributor in Noida" />

        <meta name="keywords" content="Dalnex Dealer in Gurugram" />
        <meta name="keywords" content="Best Dalnex Dealer in Gurugram" />
        <meta name="keywords" content="Dalnex Distributor in Gurugram" />

        <meta name="keywords" content="Dalnex Dealer in Delhi" />
        <meta name="keywords" content="Best Dalnex Dealer in Delhi" />
        <meta name="keywords" content="Dalnex Distributor in Delhi" />

        <meta name="keywords" content="Dalnex Dealer in Kolkata" />
        <meta name="keywords" content="Best Dalnex Dealer in Kolkata" />
        <meta name="keywords" content="Dalnex Distributor in Kolkata" />

        <meta name="keywords" content="Dalnex Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Dalnex Dealer in Bhubaneswar" />
        <meta name="keywords" content="Dalnex Distributor in Bhubaneswar" />

        <meta name="keywords" content="Dalnex Dealer in Hyderabad" />
        <meta name="keywords" content="Best Dalnex Dealer in Hyderabad" />
        <meta name="keywords" content="Dalnex Distributor in Hyderabad" />

        <meta name="keywords" content="Dalnex Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Dalnex Dealer in Visakhapatnam" />
        <meta name="keywords" content="Dalnex Distributor in Visakhapatnam" />

        <meta name="keywords" content="Dalnex Dealer in Chennai" />
        <meta name="keywords" content="Best Dalnex Dealer in Chennai" />
        <meta name="keywords" content="Dalnex Distributor in Chennai" />

        <meta name="keywords" content="Dalnex Dealer in Coimbatore" />
        <meta name="keywords" content="Best Dalnex Dealer in Coimbatore" />
        <meta name="keywords" content="Dalnex Distributor in Coimbatore" />

        <meta name="keywords" content="Dalnex Dealer in Bengaluru" />
        <meta name="keywords" content="Best Dalnex Dealer in Bengaluru" />
        <meta name="keywords" content="Dalnex Distributor in Bengaluru" />

        <meta name="keywords" content="Dalnex Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Dalnex Dealer in Tamil Nadu" />
        <meta name="keywords" content="Dalnex Distributor in Tamil Nadu" />

        <meta name="keywords" content="Dalnex Dealer in Karnataka" />
        <meta name="keywords" content="Best Dalnex Dealer in Karnataka" />
        <meta name="keywords" content="Dalnex Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Discover Dalnex analytics solutions at Calid Technologies. Leverage advanced data analytics tools to optimize your manufacturing processes and gain actionable insights. Serving Mumbai, Pune, and across India."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FDT Dalnex" />
        <meta
          name="twitter:description"
          content="Discover Dalnex analytics solutions at Calid Technologies. Leverage advanced data analytics tools to optimize your manufacturing processes and gain actionable insights. Serving Mumbai, Pune, and across India."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Discover Dalnex analytics solutions at Calid Technologies. Leverage advanced data analytics tools to optimize your manufacturing processes and gain actionable insights. Serving Mumbai, Pune, and across India"
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://www.calidtech.com/ManufacturingDalnexSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Analytics - Dalnex
      </div>

      <div className="dalnex-subproduct-nav">
        <button
          className={`dalnex-subproduct-nav-button ${
            currentSubproduct === 1 ? "active-dalnex-subproduct" : ""
          }`}
          onClick={() => switchSubproduct(1)}
        >
          Dalnex
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="dalnex-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-dalnex-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-dalnex" : ""}
                >
                  <button
                    className="dalnex-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <ContactButtons/>

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingDalnexSubcategoryPage;
